import React, { useEffect } from "react";
import { ENDPOINT } from "../axios/apiConfig";
import { loader } from "../loader";
import {postData} from "../axios/apiHelper"
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
const Redirect =()=>{
    const navigate = useNavigate();
      const queryParams = queryString.parse(window.location.search);

    useEffect(() => {
        //  myFun();
         login()
    },
 []);
 const login = async() =>{
     loader("show")
    let newUrl = queryParams["encf"]?ENDPOINT.NEWDIRECTLOGIN:ENDPOINT.NEWLOGIN
    const res = await postData(newUrl, {
      "trailuserid":queryParams['user-id']
    });
    
    localStorage.setItem("token-id", res?.data?.data?.token);
    localStorage.setItem("username", res?.data?.data?.email);
    localStorage.setItem("encryped_id", res?.data?.data?.encryped_id);
    localStorage.setItem("loginToken", res?.data?.data?.loginToken);
    const userDetail = {
      email: res.data.data?.email,
      user_id: res?.data?.data?.user_id,
      speciality: res.data.data?.speciality,
      content_option: res?.data?.data?.content_option,
      image: res?.data?.data?.image,
      name: res?.data?.data?.name,
      digest_status: res?.data?.data?.digest_status,
      country: res?.data?.data?.country,
    };
    localStorage.setItem("ssi", 1);
    localStorage.setItem("user_detail", JSON.stringify(userDetail));
    loader("hide")
    navigate("/library");
 }
  // const myFun = () => { 
  //     loader("show");
  //    };
    return (
        <>
        </>
    )


}
export default Redirect