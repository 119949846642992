import React from "react";
import { useState, useEffect } from "react";

import { loader } from "../loader";
import { useTranslation, Trans } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import ErrorModel from "./ErrorModel";

const Digest = () => {
  const [message, setMessage] = useState("");
  const [treatment, setTreatment] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [competetion, setCompetetion] = useState([]);
  const [errorShow, setErrorShow] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const [show, setShow] = useState(false);
  const [productName, setProductName] = useState("");
  const [indication, setIndication] = useState([
    {
      indication: "",
    },
  ]);

  const [product, setProduct] = useState([
    {
      product: "",
    },
  ]);

  const [keyword, setKeyword] = useState([
    {
      keyword: "",
    },
  ]);

  const addMoreIndication = () => {
    setIndication([
      ...indication,
      {
        indication: "",
      },
    ]);
  };

  const addMoreProduct = () => {
    setProduct([
      ...product,
      {
        product: "",
      },
    ]);
  };

  const addMoreKeyword = () => {
    setKeyword([
      ...keyword,
      {
        keyword: "",
      },
    ]);
  };

  const [counterFlag, setCounterFlag] = useState(0);

  const deleteRecord = (i) => {
    //  console.log(hpc);
    const list = indication;
    list.splice(i, 1);

    console.log(list);
    // // console.log("list after splice");
    // // console.log(list);
    // // console.log(typeof list);
    setIndication(list);
    setCounterFlag(counterFlag + 1);
  };
  const saveButtonClicked = async () => {
    try {
      let body = {
        LangCode: i18n.language,
        main_category: productName,
        treatment: indication,
        interaction: keyword,
        product: product,
        //  token: localStorage.getItem("token-id"),
      };
      loader("show");
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      const res = await postData(ENDPOINT.ALBERTMAINCATEGORY, body);

      if (res.status == 201 || res.status == 200) {
        setShow(true);
        loader("hide");
        // console.log(res);
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }

    // console.log(res);
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    try {
      let body = {
        App_used: "docintel",
        Build_number: "1.0",
        Device_used: "Web",
        LangCode: i18n.language,
        device_id: "web",
        //  token: localStorage.getItem("token-id"),
        method: "user_info",
      };
      loader("show");
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      const res = await postData(ENDPOINT.GETPROFILE, body);

      if (res.status == 200) {
        console.log(res);
        setIndication([]);
        setKeyword([]);
        setProduct([]);
        setProductName(res.data.digest.main_category);

        const treatment = res.data.digest.subCategory.filter((data) => {
          return data.slug === "treatment";
        });

        const treatment_data = treatment.map((data) => {
          const slug_value = setIndication((oldArray) => [
            ...oldArray,
            {
              indication: data.slug_value,
            },
          ]);

          //return data.slug_value;
        });
        if (treatment_data.length == 0) {
          setIndication([
            {
              indication: "",
            },
          ]);
        }

        const keyword = res.data.digest.subCategory.filter((data) => {
          return data.slug === "interaction";
        });

        const keyword_data = keyword.map((data) => {
          const slug_value = setKeyword((oldArray) => [
            ...oldArray,
            {
              keyword: data.slug_value,
            },
          ]);
          //return data.slug_value;
        });

        if (keyword_data.length == 0) {
          setKeyword([
            {
              keyword: "",
            },
          ]);
        }

        const competetion = res.data.digest.subCategory.filter((data) => {
          return data.slug === "product";
        });

        const competetion_data = competetion.map((data) => {
          const slug_value = setProduct((oldArray) => [
            ...oldArray,
            {
              product: data.slug_value,
            },
          ]);
          //return data.slug_value;
        });

        if (competetion_data.length == 0) {
          setProduct([
            {
              product: "",
            },
          ]);
        }

        loader("hide");
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  };

  const deleteRecordKeyword = (i) => {
    // console.log(hpc);
    const list = keyword;
    console.log(i);
    console.log(list);

    list.splice(i, 1);

    // console.log(list);
    // // console.log("list after splice");
    // // console.log(list);
    // // console.log(typeof list);
    setKeyword(list);
    setCounterFlag(counterFlag + 1);
  };

  const productNameChanged = (e) => {
    setProductName(e.target.value);
  };

  const deleteRecordProduct = (i) => {
    //  console.log(hpc);
    const list = product;
    // console.log(i);
    // console.log(list);
    list.splice(i, 1);

    // console.log(list);
    // // console.log("list after splice");
    // // console.log(list);
    // // console.log(typeof list);
    setProduct(list);
    setCounterFlag(counterFlag + 1);
  };

  const onIndicationChange = (e, i) => {
    const { value } = e.target;
    const list = [...indication];
    const name = indication[i].indication;
    list[i].indication = value;
    setIndication(list);
  };

  const onKeywordChange = (e, i) => {
    const { value } = e.target;
    const list = [...keyword];
    const name = keyword[i].keyword;
    list[i].keyword = value;
    setKeyword(list);
  };

  const onProductChange = (e, i) => {
    const { value } = e.target;
    const list = [...product];
    const name = product[i].product;
    list[i].product = value;
    setProduct(list);
  };

  return (
    <>
      <header className="top-header">
        <div className="container">
          {console.log(indication)}
          <div className="header-back">
            <button onClick={() => navigate("/settings")} className="">
              <svg
                width="30"
                height="30"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                  fill="#fff"
                ></path>
              </svg>
            </button>

            <div className="logo">
              <img src={path_image + "albert-logo.png"} />
            </div>
          </div>
        </div>
      </header>
      <section className="sectionul-list div_1">
        <ul>
          <li className="active">
            {" "}
            <a href="#section-first">
              <span className="bg-blue"></span>
            </a>
          </li>
          <li>
            {" "}
            <a href="#section-second">
              <span className="bg-green"></span>
            </a>
          </li>
          <li>
            {" "}
            <a href="#section-third">
              <span className="bg-yellow"></span>
            </a>
          </li>
          <li>
            {" "}
            <a href="#section-four">
              <span className="bg-pink"></span>
            </a>
          </li>
        </ul>
      </section>
      <section id="section-first" className="bg-blue content-blocks">
        <figure>
          <img src={path_image + "bg-shapes.png"} alt="bg-shapes" />
        </figure>
        <figcaption>
          <h2>Welcome to the Albert Drug Digest.</h2>

          <p>
            Thanks for registering to set up your trial with us. We will ask you
            to enter:{" "}
          </p>
          <ul>
            <li>- The name(s) of your product</li>
            <li>- Relevant treatment/indications/mechanisms </li>
            <li>- Alternatives/competitors to your product</li>
          </ul>
          <p>
            {" "}
            You can change these at any time and as often as you wish until you
            get it just right.
          </p>
          <button
            type="button"
            className="btn btn-primary  start-btn content-sec"
          >
            <a href="#section-second" className="clr_bh">
              Next
            </a>
          </button>
        </figcaption>
      </section>
      <section id="section-second" className="bg-green content-blocks div_2">
        <figure>
          <img src={path_image + "bg-shapes.png"} alt="bg-shapes" />
        </figure>
        <figcaption>
          <h5>
            What is your product? (Please enter different names for your product
            on separate lines)
          </h5>
          <form id="form_sec_2">
            <div className="form-group row ">
              <label for="staticEmail" className="col-sm-2 col-form-label">
                Name
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  onChange={(e) => productNameChanged(e)}
                  value={productName}
                  className="form-control-plaintext"
                />
              </div>
            </div>
          </form>
          <button
            type="button"
            href="#section-third"
            className="btn btn-primary  start-btn content-sec-2"
          >
            <a href="#section-third" className="clr_wh">
              Next
            </a>
          </button>
        </figcaption>
      </section>
      <section id="section-third" className="bg-yellow content-blocks div_3">
        <figure>
          <img src={path_image + "bg-shapes.png"} alt="bg-shapes" />
        </figure>
        <figcaption>
          <h5>
            What does it treat? (Please enter each indication/use for your
            product on a separate line)
          </h5>
          <form id="form_section3">
            <div id="treat" className="form-group row ">
              {/* {indication.map((data, i) => {
                const fieldName = `indication[${i}]`;

                return (
                  <>
                    Indication{" "}
                    <input
                      type="text"
                      onChange={(e) => onIndicationChange(e, i)}
                      name={`${fieldName}.indication`}
                      value={data.indication}
                    />{" "}
                    {indication.length !== 1 && (
                      <button onClick={() => deleteRecord(i)}>Remove</button>
                    )}
                  </>
                );
              })} */}
              {indication.map((data, i) => {
                const fieldName = `indication[${i}]`;
                return (
                  <>
                    <div className="horizontal_div">
                      <label for="treat" className="col-sm-2 col-form-label">
                        {" "}
                        Treatment
                      </label>
                      <div className="col-sm-10 product-info">
                        <input
                          type="text"
                          onChange={(e) => onIndicationChange(e, i)}
                          value={data.indication}
                          className="form-control-plaintext treatment_in"
                        />
                        {indication.length !== 1 && (
                          <button
                            style={{ display: "inlineBlock" }}
                            onClick={() => deleteRecord(i)}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <a href="#sec3" className="add-tab sec3">
                <span onClick={addMoreIndication}>+</span>
              </a>
            </div>
            <h5>
              What other keywords (e.g. mechanism, product class, drug target,
              etc) do you want to hear about? (Please enter each additional
              keyword on a separate line)
            </h5>
            <div id="interact" className="form-group row ">
              {keyword.map((data, i) => {
                const fieldName = `indication[${i}]`;
                return (
                  <>
                    <div className="horizontal_div">
                      <label for="treat" className="col-sm-2 col-form-label">
                        {" "}
                        Competetion
                      </label>
                      <div className="col-sm-10 product-info">
                        <input
                          type="text"
                          onChange={(e) => onKeywordChange(e, i)}
                          value={data.keyword}
                          className="form-control-plaintext treatment_in"
                        />
                        {keyword.length !== 1 && (
                          <button onClick={() => deleteRecordKeyword(i)}>
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <a href="#sec3" className="add-tab sec3">
                <span onClick={addMoreKeyword}>+</span>
              </a>
            </div>
          </form>
          <button
            type="button"
            href="#section-four"
            className="btn btn-primary  start-btn content-sec-3"
          >
            <a href="#section-four" className="clr_wh">
              Next
            </a>
          </button>
        </figcaption>
      </section>
      <section id="section-four" className="bg-pink content-blocks div_4">
        <figure>
          <img src={path_image + "bg-shapes.png"} alt="bg-shapes" />
        </figure>
        <figcaption>
          <h5>
            What competition or alternatives do you want to keep an eye on?
            (Please enter each one on a separate line)
          </h5>

          <form id="form_sec_4">
            <label for="treat" className="col-sm-2 col-form-label">
              {" "}
              product
            </label>
            <div className="form-group">
              {product.map((data, i) => {
                const fieldName = `product[${i}]`;
                return (
                  <>
                    <div className="horizontal_div">
                      <label
                        for="staticEmail"
                        className="col-sm-3 col-form-label"
                      >
                        {" "}
                        Product
                      </label>
                      <div className="col-sm-9 product-info">
                        <input
                          type="text"
                          onChange={(e) => onProductChange(e, i)}
                          value={data.product}
                          className="form-control-plaintext treatment_in"
                        />
                        {product.length !== 1 && (
                          <button onClick={() => deleteRecordProduct(i)}>
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              {/* <div className="row" id="sec_4_row">
                <label for="staticEmail" className="col-sm-3 col-form-label">
                  Product
                </label>
                <div className="col-sm-9">
                  <input type="text" value="" className="form-control-plaintext" />
                </div>
              </div> */}

              <a id="sec4" href="#section-four" className="add-tab">
                <span onClick={addMoreProduct}>+</span>
              </a>
            </div>
          </form>

          <button
            onClick={saveButtonClicked}
            className="btn btn-primary  start-btn"
          >
            Save
          </button>
        </figcaption>
      </section>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "like.svg"} alt="" />
            </div>
            <h6>{t("Success")}</h6>
            <p>Data saved successfully!</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                navigate("/library");
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </>
  );
};

export default Digest;
