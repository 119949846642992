export const IMAGE = {
    'Haematology': '46Blood.png',
    'Allergies':'2pollen.png',
    'Anesthesiology':'3Mask-with-hose.png',
    'Audio and speech therapy':'4speech-bubble.png',
    'Audio And Speech Therapy':'4speech-bubble.png',
    'AudioAndSpeechTherapy':'4speech-bubble.png',
    'Cardiovascular':'Heart.png',
    'Dentistry':'5Tooth.png',
    'Dermatology':'6Hair-cell.png',
    'Emergency Medicine':'8Blinking-light.png',
    'ENT':'8Nose.png',
    'Ent': '8Nose.png',
    'Fertility':'9Embryo.png',
    'Gastroenterology':'10Intestines.png',
    'Genetics':'11DNA.png',
    'Geriatics':'12Walker.png',
    'Geriatrics':'12Walker.png',
    'GP':'13Stethoscope.png',
    'Gynocology and obstetrics':'14Fallopian-Tubes.png',
    'Hepatology':'15Liver.png',
    'Hormonal':'7Blood-Pen.png',
    'Immunology':'immunology.png',
    'Intensive care':'16Bed-or-monitor.png',
    'Internal Medicine':'17Outline-of-body.png',
    'Microbiology & Parasites':'18Pathogen.png',
    'Nephrology':'19Kidney.png',
    'Neurology':'20Nerve-rereceptor.png',
    'Nursing':'21-Headband-with-cross.png',
    'Nutrition and diet':'22Food-plate-or-brocolli.png',
    'Oncology':'23Cancer-cell.png',
    'Ophthalmology':'24Glasses.png',
    'Orthopedics':'25Bones.png',
    'Pharmacy':'28-Pill.png',
    'Pathology':'26Microscope.png',
    'Pediatrics':'27Baby.png',
    'Physical Theraphy':'dumbell.png',
    'Psycolgy':'31Brain.png',
    'Psychology':'31Brain.png',
    'Mental Health':'31Brain.png',
    'Pulmonology':'32Lungs.png',
    'Radiology':'33X-Ray-Warning.png',
    'Rheumatology':'34Joint.png',
    'Sports medicine':'29Sports-gear.png',
    'Surgery':'35Scalpel.png',
    'Urology':'36Bladder.png',
    'Veterinary':'37horse.png',
    'Administration':'1pen.png',
    'Plastic, Aestathics and reconstruction':'30Lips.png'
}
