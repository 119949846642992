import react, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { loader } from "../loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { set } from "react-hook-form";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import ErrorModel from "./ErrorModel";

const ConsentSettings = () => {
  const { t, i18n } = useTranslation();
  const [trueDone, setTrueDone] = useState(false);
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [data, showData] = useState(0);
  const [dataAlbert, setDataAlbert] = useState(0);

  const [dataLibrary, setDataLibrary] = useState(0);
  const [trueDoneLibrary, setTrueDoneLibrary] = useState(false);

  const [message, setMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  const [contentLibrary, setContentLibrary] = useState(0);
  const [contentALbert, setContentAlbert] = useState(0);
  const [update, setUpdate] = useState(0);
  const [dynamicContent, setDynamicContentData] = useState([]);
  const [dynamicId, setDynamicId] = useState();
  const [dynamicName, setDynamicName] = useState("");
  const [show, setShow] = useState(false);
  const [showMinimumPopup, setShowMinimumPopup] = useState(false);

  const [checkbox3, setCheckbox3] = useState("");
  const [checkbox4, setCheckbox4] = useState("");
  const [checkbox5, setCheckbox5] = useState("");
  const [defaultc3, showDefaultc3] = useState(false);
  const [defaultc4, showDefaultc4] = useState(false);
  const [defaultc5, showDefaultc5] = useState(false);
  const [minDataAlbert, setMinDataAlbert] = useState();
  const [minDataLibrary, setMinDataLibrary] = useState();
  const [arr, setArr] = useState([]);

  const [albertConsent, setAlberConsent] = useState({
    spon_data: false,
    min_data: false,
  });

  useEffect(() => {
    getDynamicContentData();
  }, []);

  const dynamicContentCheckboxClicked = (e, index, datas) => {
    const new_arr = arr;

    if (e.target.checked == true) {
      if (arr[index].content_option.includes(datas.value) == false) {
        new_arr[index].content_option =
          new_arr[index].content_option + "~" + datas.value;
      }
    } else {
      if (arr[index].content_option.includes(datas.value) == true) {
        new_arr[index].content_option = new_arr[index].content_option.replace(
          datas.value,
          ""
        );
      }
    }
    setArr(new_arr);
  };

  useEffect(() => {
    setContentAlbert(contentALbert);
    setUpdate(update + 1);
  }, [contentALbert]);

  const getDynamicContentData = async () => {
    try {
      const body = {
        LangCode: i18n.language,
        //token: localStorage.getItem("token-id"),
      };
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show");
      const res = await postData(ENDPOINT.GETCONSENTSETTING, body);
      loader("show");

      if (res.status == 200) {
        console.log(res);
        loader("hide");
        showData(1);
        res.data.data.consent_settings.dynamic_consent.get_dynamic_consent_ios?.map(
          (data) => {
            setArr((oldArray) => [
              ...oldArray,
              {
                type: data?.name,
                content_option: data?.selected_setting,
                update_id: data?.selected_id,
              },
            ]);
          }
        );

        setDynamicContentData(
          res.data?.data?.consent_settings?.dynamic_consent
            ?.get_dynamic_consent_ios
        );
        // setAlberConsent({
        //   ...albertConsent,
        //   spon_data:res.data?.data?.consent_settings.albert?.spon_data,
        //   min_data:res.data?.data?.consent_settings.albert?.min_data
        // })

        setMinDataAlbert(res.data?.data?.consent_settings.albert.min_data);
        setMinDataLibrary(res.data?.data?.consent_settings.library.min_data);

        if (res.data?.data?.consent_settings.albert.min_data == true) {
          setDataAlbert(2);
          setTrueDone(true);
        } else {
          setDataAlbert(0);
          setTrueDone(false);
        }

        if (res.data?.data?.consent_settings.library.min_data == true) {
          setDataLibrary(2);
          setTrueDoneLibrary(true);
        } else {
          setDataLibrary(0);
          setTrueDoneLibrary(false);
        }

        if (res.data?.data?.consent_settings.albert.spon_data == true) {
          setContentAlbert(2);
        } else {
          setContentAlbert(0);
        }
        if (res.data.data?.consent_settings.library.spon_data == true) {
          setContentLibrary(2);
        } else {
          setContentLibrary(0);
        }
        loader("hide");
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }

    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const albertDataChanged = (e) => {
    if (e.target.checked == true) {
      setDataAlbert(2);
      setTrueDone(true);
    } else {
      //  setTrueDone(true);
      setShowMinimumPopup(true);
      setDataAlbert(0);
    }
  };

  const libraryDataChanged = (e) => {
    if (e.target.checked == true) {
      setDataLibrary(2);
      setTrueDoneLibrary(true);
    } else {
      setShowMinimumPopup(true);
      setDataLibrary(0);
    }
  };

  const albertContentDataChanged = (e) => {
    if (e.target.checked == true) {
      setContentAlbert(2);
    } else {
      setContentAlbert(0);
    }
  };

  const libaryContentCheckboxChanged = (e) => {
    if (e.target.checked == true) {
      setContentLibrary(2);
    } else {
      setContentLibrary(0);
    }
  };

  const saveClicked = async (e) => {
    e.preventDefault();
    try {
      if (trueDone == true && trueDoneLibrary == true) {
        let body = {
          App_used: "docintel",
          Build_number: "1.0",
          Device_used: "Web",
          LangCode: i18n.language,
          device_id: "web",
          //  token: localStorage.getItem("token-id"),
          method: "updateContentOption",
          change_data: [
            { type: "library", content_option: contentLibrary, update_id: 0 },
            { type: "albert", content_option: contentALbert, update_id: 0 },
          ],
        };

        arr.map((data) => {
          body.change_data.push(data);
        });
        axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
        loader("show");
        const res = await postData(ENDPOINT.CONSENTSETTING, body);

        if (res.status == 200) {
          console.log(res);
          setShow(true);
          let userDetail = JSON.parse(localStorage.getItem("user_detail"));
          userDetail.content_option = contentALbert;
          localStorage.setItem("user_detail", JSON.stringify(userDetail));
          loader("hide");
        }

        // .catch((err) => {
        //   console.log(err);
        // });
      } else {
        setShowMinimumPopup(true);
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  };

  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div
          className="btn-back"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <button className="btn btn-primary btn-back">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                fill="#0066BE"
              ></path>
            </svg>
          </button>
        </div>
        <h6>{t("Consent Settings")}</h6>
      </div>
      <div className="wrapper">
        <div className="user-profile">
          <div className="container">
            <div className="row">
              <div className="user-detail-inset setting-consent">
                <h4> {t("Consent Settings")}</h4>
                {data == 1 ? (
                  <form>
                    <div className="form-group">
                      <label for="albert">{t("Albert")}</label>
                      <div className="albert-block">
                        <div className="switch6">
                          <label className="switch6-light" onClick="">
                            {dataAlbert == 2 ? (
                              <input
                                type="checkbox"
                                checked={true}
                                //  defaultChecked={true}
                                onChange={(e) => {
                                  if (trueDone == false) {
                                    albertDataChanged(e);
                                  } else {
                                    setShowMinimumPopup(true);
                                  }
                                }}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  albertDataChanged(e);
                                }}
                              />
                            )}

                            <span>
                              <span>{t("Yes")}</span>
                              <span>{t("No")}</span>
                            </span>
                            <a className="btn"></a>
                          </label>
                        </div>
                        <p>{t("Minimum data")}</p>
                      </div>
                      <div className="albert-block">
                        <div className="switch6">
                          <label className="switch6-light" onClick="">
                            {contentALbert == 2 ? (
                              <input
                                type="checkbox"
                                checked={true}
                                //  defaultChecked={true}
                                onChange={(e) => {
                                  albertContentDataChanged(e);
                                }}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  albertContentDataChanged(e);
                                }}
                              />
                            )}
                            <span>
                              <span className="switch-btn active">
                                {t("Yes")}
                              </span>
                              <span className="switch-btn">{t("No")}</span>
                            </span>
                            <a className="btn"></a>
                          </label>
                        </div>
                        <p>{t("Sponsored content also")}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="library">{t("Library")}</label>
                      <div className="albert-block">
                        <div className="switch6">
                          <label className="switch6-light" onClick="">
                            {dataLibrary == 2 ? (
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={(e) => {
                                  if (trueDoneLibrary == false) {
                                    libraryDataChanged(e);
                                  } else {
                                    setShowMinimumPopup(true);
                                  }
                                }}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                //  checked={true}
                                onChange={(e) => {
                                  libraryDataChanged(e);
                                }}
                              />
                            )}

                            <span>
                              <span>{t("Yes")}</span>
                              <span>{t("No")}</span>
                            </span>
                            <a className="btn"></a>
                          </label>
                        </div>
                        <p>{t("Minimum data")}</p>
                      </div>
                      <div className="albert-block">
                        <div className="switch6">
                          <label className="switch6-light" onClick="">
                            {contentLibrary == 2 ? (
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={(e) => {
                                  libaryContentCheckboxChanged(e);
                                }}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  libaryContentCheckboxChanged(e);
                                }}
                              />
                            )}
                            <span>
                              <span>{t("Yes")}</span>
                              <span>{t("No")}</span>
                            </span>
                            <a className="btn"></a>
                          </label>
                        </div>
                        <p>{t("Sponsored content also")}</p>
                      </div>
                    </div>

                    <div className="form-group">
                      {dynamicContent.map((data, index) => {
                        return (
                          <>
                            <label for="library">{data.name}</label>

                            {data.setting.map((datas, i) => {
                              return (
                                <>
                                  <div className="albert-block">
                                    <div className="switch6">
                                      <label
                                        className="switch6-light"
                                        onClick=""
                                      >
                                        {datas.name ==
                                        "Receive content with Docintel’s Terms & Conditions and Privacy Policy" ? (
                                          <input
                                            type="checkbox"
                                            checked
                                            onClick={(e) => {
                                              toast.warning(
                                                "This is required field."
                                              );
                                            }}
                                          />
                                        ) : (
                                          <input
                                            type="checkbox"
                                            defaultChecked={datas.selected}
                                            onChange={(e) => {
                                              dynamicContentCheckboxClicked(
                                                e,
                                                index,
                                                datas
                                              );
                                            }}
                                          />
                                        )}

                                        <span>
                                          <span>{t("Yes")}</span>
                                          <span>{t("No")}</span>
                                        </span>
                                        <a className="btn"></a>
                                      </label>
                                    </div>
                                    <p>

                                    {datas.name ==  "Receive content with Docintel’s Terms & Conditions and Privacy Policy" ? 
                                    ( 
                                      <>
                                          Receive content with <a href= "https://albert.docintel.app/privacy_policy/" target="_blank" rel="noreferrer" >Docintel's Terms & Conditions </a> and <a href= "https://albert.docintel.app/octapharma-privacy"  target="_blank" rel="noreferrer" >Privacy Policy</a> 
                                      </>
                                    
                                        
                                     ) 
                                     :

                                     datas.name ==  "Octapharma account and content according to privacy policy of Docintel and privacy statement of Octapharma AG and terms of use" ? 
                                     ( 
                                      <>
                                      Octapharma account and content according to <a href= "https://albert.docintel.app/privacy_policy/"  target="_blank" rel="noreferrer" >privacy policy of Docintel</a> and <a href= "https://albert.docintel.app/octapharma-trail-privacy" target="_blank" rel="noreferrer" >privacy statement of Octapharma AG </a> and <a href= "https://albert.docintel.app/terms_of_use/"  target="_blank" rel="noreferrer" >terms of use</a> 
                                  </>
                                     
                                    
                                        
                                     ) 
                                     : 
                                     datas.name ==  "One source account and content according to privacy policy of Docintel and privacy statement of Octapharma AG and terms of use" ? 
                                     ( 
                                      <>
                                          One source account and content according to <a href= "https://albert.docintel.app/privacy_policy/"  target="_blank" rel="noreferrer" >privacy policy of Docintel</a> and <a href= "https://albert.docintel.app/octapharma-privacy/" target="_blank" rel="noreferrer" >privacy statement of Octapharma AG </a> and <a href= "https://albert.docintel.app/terms_of_use/"  target="_blank" rel="noreferrer" >terms of use</a> 
                                      </>
                                        
                                     ) 
                                     : 
                                     (
                                            datas.name
                                          
                                          )
                                          
                                        }
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>

                    <button
                      onClick={(e) => saveClicked(e)}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {t("Save")}
                    </button>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "like.svg"} alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p>{t("Consent settings updated successfully!")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={showMinimumPopup}
        onHide={() => {
          setShowMinimumPopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "alert.svg"} alt="" />
            </div>
            <h6>{t("Minimum consent required.")}</h6>
            <p>
              {t(
                "Compilance require us to track who our readers are. Please register and consent to at least the Docintel ToU & Privacy setting."
              )}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowMinimumPopup(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </>
  );
};

export default ConsentSettings;
