import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;

const ErrorModel = ({ show, handleClose, message }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "alert.svg"} alt="" />
            </div>
            <h6>
              {t(
                message
                  ? message
                  : "OOPS Something went wrong.Please try again!"
              )}
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>{t("Close")}</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ErrorModel;
