import React,{useState} from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const Footer1 = () => {
  const [show, setShow] = useState(false);
  const [isCookies, setIsCookies] = useState(false);
  const [cookieSection, setCookieSection] = useState(true)
  const [acceptedCookies, setAcceptedCookies] = useState(localStorage.getItem('acceptedCookies'));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCookieAccept = () => {
  localStorage.setItem('acceptedCookies', 'true');
  setAcceptedCookies(true);
  };
  
  const navigate = useNavigate();
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  return (
    <>
    <footer className={!isCookies?`cookie_show`:""}>
      <Container>
        <Row>
          <div className="footer-inset">
            <div className="footer-logo">
              <img src={path_image + "docintel-white.svg"} alt="" />
            </div>
            {/* <div className="social-links">
              <span>Keep in touch!</span>
              <div className="social-links-list">
                <a
                  href="https://www.facebook.com/Docintelapp-383593615769917/"
                  target="_blank"
                >
                  <img src={path_image + "facebook.png"} alt="Facebook" />
                </a>
                <a href="https://twitter.com/DocintelA?t=7LM_ahRYQUcoL5RpKZCzoQ&s=03" target="_blank">
                  <img src={path_image + "twitter.png"} alt="Twitter" />
                </a>
                <a href="https://www.linkedin.com/company/docintelapp/" target="_blank">
                  <img src={path_image + "linkedin.png"} alt="LinkedIn" />
                </a>
                <a href="https://www.instagram.com/Docintel.App/" target="_blank">
                  <img src={path_image + "instagram.png"} alt="Instagram" />
                </a>
              </div>
            </div> */}
            <div className="terms-condition">
              <span>
                Copyright MedArkive Ltd.
                <br /> Read our{" "}
                <a href="/docintel-privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="/docintel-terms" target="_blank">
                  Terms of Use
                </a>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </footer>

    { !acceptedCookies && cookieSection && (
    <div className={!isCookies?`cookie-consent show-consent`:`cookie-consent`}>
      <Container>
        <Row>
          <div className="cookie-popup-inset d-flex justify-content-between align-items-center">
            <span className="cookie-text">We only use essential cookies to ensure your experiance is smooth and easy. We never share your details with commercial cookie companies. Only the company you got this from and where relevant the owner of the content will get some relevent data. Please click to see the specifics, otherwise by continuing reading you will have shown your acceptance of the cookies we use. <a href="javascript:viod(0);" onClick={handleShow}>Click Here</a>. To remove this message please click ok </span>
            <div class="cookie-popup-btns"><Button variant="primary" 
            // onClick={(()=>setIsCookies(true))}
            onClick={handleCookieAccept}
            >Ok</Button></div>
          </div>
         
        </Row>
      </Container>
    </div>
     )}

     <div className="overlay"></div>
    <Modal className="cookies-popup" show={show} onHide={handleClose} centered>
      <div className="cookies-popup-inset">
        <Modal.Header closeButton>
          <Modal.Title>Used Cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="used-cookies-table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Purpose</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>userlang</td>
                  <td>For storing language preferance of user</td>
                </tr>
                <tr>
                  <td>LANGUAGE_COOKIE_NAME</td>
                  <td>For storing language preferance of user </td>
                </tr>
                <tr>
                  <td>csrftoken</td>
                  <td>This cookie helps to authenticated calls to webservices.</td>
                </tr>
                </tbody>
            </table>
		  </div>
        </Modal.Body>
      </div>
      </Modal>
    </>

  );
};

export default Footer1;
