

import React from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import { UserAgent } from "react-useragent";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import queryString from "query-string";
import { Link } from "react-router-dom";

const OctapharmaTrailPrivacy = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    if (queryParams.LangCode == "ru") {
      i18n.changeLanguage("ru");
    } else if (!queryParams.LangCode) {
      i18n.changeLanguage(i18n.language);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const location = useLocation();

  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;

  return (
    <>
      <UserAgent
        render={({ ua }) => {
          return (
            <>
              {" "}
              {ua.md.ua.includes("App-API") ? null : (
                <header className="default-page">
                  <div className="main_n">
                    <div className="row">
                      <div className="col-md-3 col-sm-3" id="left_menu">
                      </div>
                      <div className="tabs_menu col-md-6 col-sm-6">
                        <div className="site-main-log1">
                          {/* <h4>{t("Privacy")}</h4> */}
                        </div>
                      </div>
                      <div id="right_menu" className="col-md-3 col-sm-3"></div>
                    </div>
                  </div>
                </header>
              )}{" "}
              <div class="wrapper-site">
                <div class="container">
                  <div class="row">
                    <div class="privacy  default-view">
                      <div class="privacy-policy">
                        {/* <h4>Privacy</h4> */}
                        <div className="site-logo">
                            <img
                              style={{ cursor: "pointer" }}
                              src={"https://albert.docintel.app/images/octapharma-logo.svg"}
                              alt="Docintel Logo"
                            />
                        </div>
                        <div className="privacy-content">
                          <p>
                          <b>  Data Privacy Statement </b> <br/><br/>
                          Octapharma AG, Seidenstrasse 2, 8853 Lachen SZ, Switzerland (hereinafter “Octapharma” or “we” or “us”) respects the right of individuals regarding their personal data. This Data Privacy Statement (“privacy statement”) describes the way we process your personal information. Octapharma Pharmazeutika Produktionsgesellschaft m.b.H., Oberlaaer Strasse 235, 1100 Vienna, Austria is appointed as our representative in the EU/EEA in accordance with Article 27 GDPR.</p> 
                          <p>
                          Personal information will not be disclosed to third parties except where the third party is a contractor who is involved in fulfilling the individual’s request for information, or a supplier or contractor who is involved in the development and management of the relevant website, app and/or system. </p> 
                          <p>
                          <b> <i style={{"text-decoration": "underline"}}>  Legal basis</i> </b> <br/> <br/> 
                          Mandatory trainings: We provide training to health care professionals, who support us in clinical studies, and track the progress in accordance with applicable laws and legitimate interest.
                            </p>
                            <p>
                            Non-mandatory trainings: We provide training material to health care professionals, who support us in clinical studies, on the basis of legitimate interest. Octapharma will not track on an individual user basis if you have accessed these documents or not. The same (i.e. “will not track on an individual user basis”) applies for study related communication with such health care professionals.  
                          </p>
                          <p>
                          Any further purpose: Participation in other interactions (e.g. surveys etc.) is subject to the explicit consent provided by the relevant health care professional.  
                          </p>
                          <b> <i style={{"text-decoration": "underline"}}>Your rights</i> </b> <br/><br/>
                          <p>
                          You can access all your personally identifiable information that we collect. You may also demand the deletion of your personal data unless the applicable laws and regulations oblige us to store your personal data.</p>
                          <p>
                          You can request to be provided with information about data which we store/process about you. In addition, you have the right to have any personal data blocked or deleted, to object to the processing of your data, to have any incorrect data corrected and the right to have your data transferred to a third party.</p> 
                          <p>
                          You can correct factual errors in your personally identifiable information by sending us an email that credibly shows the error. You likewise have the right to request the correction of incorrect personal data. </p> 
                          <p>
                          To protect your privacy and security, we will also take reasonable steps to verify your identity before making corrections. Should you have other questions or concerns about these privacy statement or want to have your data deleted from our database, please send an email to <i style={{"text-decoration": "underline"}}><a href="mailto:dataprivacy@octapharma.com" style={{color:"#004a89"}}>dataprivacy@octapharma.com</a> </i></p>
                          <p> 
                           You also have the right to lodge a complaint with a supervisory authority, if applicable.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
};
export default OctapharmaTrailPrivacy;
