import React from "react"
import "./assets/digest.css"
const DigestRegister = () =>{
    return (
        <>
        <div class="container first_container">
         <div class="row">
          <div class="col">
         <div className="main-content">
         <h2 className="welcome_add_title">
         Welcome to the
         <br />
         Albert Digests
         </h2>
         <p>
         Albert reads every article published every day .
         <br />
         You can create your own personalised stream in just 5 minutes.
         <br />
         You can share any interesting articles with colleagues.
         </p>
         </div>
        
        </div>
        <div class="col">
        <div className="main-content-form">
           <div className="main-content-form-outer">
             <ul className="ulnav nav-tabs">
                <li className="navli-item">
                    <a href="#" className="nav-link">Clinic</a>
                </li>
                <li className="navli-item">
                <a href="#" className="nav-link active">Corporate</a>
                    </li>
                <li class="navli-item">
                <a href="#" className="nav-link">Publisher</a>
                    </li>
             </ul>
           </div>
         </div>        
        </div>
      </div>
        </div> 
        <div className="second-section ">
           <div className="container">
            
            {/* <ul className="category_ul">
              <li className="category_li_first">
              </li>
              <li  className="category_li_second">
                <img src={"https://albert.docintel.app/static/digest_assets/images/doc-intel.png"} />
              </li>
            </ul> */}
            <h2>Clinical Digest</h2>
            <h4>Albert is great at finding articles of clinical value, and he can collaborate with you to put them into context. For this we are letting clinicians build their own digests to share with peers.</h4>
             <div className="row">
              
              <div className="col-md-6 left-side">

                  <div className="left-side-content">
                  <div className="side-content-images">
            <span>
               <img src={"https://albert.docintel.app/static/digest_assets/images/doc-intel-logo-icon.png"} style={{width:"88px"}} />
            </span>
            <span>
              <img src={"https://albert.docintel.app/static/digest_assets/images/doc-intel.png"} />
            </span>
            <span className="third-image">
              {/* <img src={"https://albert.docintel.app/static/digest_assets/images/doc-intel-logo-icon.png"} /> */}
            </span>
            </div>
                      <p>
                      Epistaxis in the setting of antithrombotic therapy: A comparison between factor Xa inhibitors, warfarin, and antiplatelet agents.
                      </p>
                      <h3>
                      This changes previous standards, but compared to the old standard should also provide a significant improvement for the patients affected. It’s an absolut must-read.
                      </h3>
                      <h6>
                      The American Laryngological, Rhinological and Otological Society 
                      </h6>
                    </div>
              </div>

              <div className="col-md-6 right-side">
                <div className="right-side-content">
                <p>A Clinical Digest is created by a clinician who selects weekly highlights and comments on each selected article. This provides context for why the article is important to the digest's audience. </p>
                 <p>Comments can be a reference to national guidelines, previous outcomes, or a shared patient experience.</p>
                </div>
                  </div>


             </div>

          
           </div>
            
        </div>
        <div className="third-section">
          <div className="row">
            <div className="col-md-6">
               12
            </div>
            <div className="col-md-6">
               12
            </div>

          </div>

        </div>
        </>
    )
}
export default DigestRegister