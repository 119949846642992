import React, { useEffect, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  DropdownButton,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const ChangeLanguage = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [languageSelected, setLanguageSelected] = useState("");
  const [validator] = React.useState(new SimpleReactValidator());
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const [language, setLanguage] = useState("");

  const [update, setUpdate] = useState(0);

  const saveButtonClicked = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setShow(true);
      // console.log(e.target.value);
      if (e.target.value == "en") {
        i18n.changeLanguage("en");
        // setLanguage(e.target.value);
      } else if (e.target.value == "ru") {
        i18n.changeLanguage("ru");
        //setLanguage(e.target.value);
      } else {
        i18n.changeLanguage(language);
        // setLanguage(e.target.value);
      }
    } else {
      validator.showMessages();
      setRenderAfterValidation(renderAfterValidation + 1);
    }
  };

  const languageChanged = (e) => {
    // console.log(e);
    if (e == "english") {
      setLanguage("en");
      setLanguageSelected("English");
    } else if (e == "russian") {
      setLanguage("ru");
      setLanguageSelected("Russian");
    } else {
      setLanguage("es");
      setLanguageSelected("Spanish");
    }
  };
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (lang == "es") {
      setLanguage("es");
      setLanguageSelected("Spanish");
    } else if (lang == "ru") {
      setLanguage("ru");
      setLanguageSelected("Russian");
    } else {
      setLanguage("en");
      setLanguageSelected("English");
    }
  }, []);

  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div
          className="btn-back"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <button className="btn btn-primary btn-back">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                fill="#0066BE"
              ></path>
            </svg>
          </button>
        </div>
        <h6>{t("Change Password")}</h6>
      </div>
      <div className="user-profile">
        <div className="container">
          <div className="row">
            <div className="user-detail-inset">
              <h4> {t("Change Language")}</h4>
              <form>
                <div className="form-group">
                  <DropdownButton
                    name="profile"
                    id="dropdown-basic"
                    onSelect={(event) => languageChanged(event)}
                    title={
                      languageSelected != "" && languageSelected != "undefined"
                        ? t(languageSelected)
                        : t("Select Language")
                    }
                  >
                    <div className="dropdown-menu-list">
                      <Dropdown.Item eventKey="english">
                        {t("English")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="russian">
                        {t("Russian")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Spanish">
                        {t("Spanish")}
                      </Dropdown.Item>
                    </div>
                  </DropdownButton>

                  {/* <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    //value={language}
                    onChange={languageChanged}
                  >
                    <option value="en">{t("English")}</option>
                    <option value="ru">{t("Russian")}</option>
                    <option value="es">{t("Spanish")}</option>
                  </select> */}

                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_587_259)">
                        <path
                          d="M17.8452 8.15869H10.3139C9.12567 8.15869 8.15903 9.12534 8.15903 10.3135V14.4053L6.20773 15.7991C6.05377 15.9089 5.96237 16.0865 5.96237 16.2758C5.96237 16.4651 6.05377 16.6427 6.20773 16.7526L8.18253 18.1631C8.33679 19.201 9.23386 19.9998 10.3139 19.9998H17.8452C19.0334 19.9998 20 19.0332 20 17.8448V10.3135C20 9.12534 19.0334 8.15869 17.8452 8.15869ZM15.3128 16.392C15.1747 16.392 15.0681 16.3482 15.0368 16.2289L14.7981 15.3941H13.3607L13.1224 16.2289C13.091 16.3482 12.9843 16.392 12.8462 16.392C12.6265 16.392 12.3315 16.2541 12.3315 16.0533C12.3315 16.0406 12.3378 16.0156 12.3441 15.9904L13.5555 12.0427C13.6119 11.8544 13.8441 11.7663 14.0764 11.7663C14.3149 11.7663 14.5471 11.8544 14.6036 12.0427L15.815 15.9904C15.8212 16.0156 15.8275 16.0344 15.8275 16.0533C15.8275 16.2477 15.5325 16.392 15.3128 16.392Z"
                          fill="#97B6CF"
                        />
                        <path
                          d="M13.5303 14.7539H14.6225L14.0762 12.8271L13.5303 14.7539Z"
                          fill="#97B6CF"
                        />
                        <path
                          d="M6.98715 10.3137C6.98715 9.52698 7.26227 8.80371 7.72049 8.23349C7.052 8.23349 6.43173 8.02429 5.92041 7.66891C5.40909 8.02444 4.78882 8.23349 4.12018 8.23349C3.93784 8.23349 3.78983 8.08548 3.78983 7.90298C3.78983 7.72049 3.93784 7.57263 4.12018 7.57263C4.58847 7.57263 5.0267 7.44293 5.40192 7.21832C4.94934 6.72989 4.64844 6.09924 4.57565 5.40131H4.12033C3.93784 5.40131 3.78983 5.25345 3.78983 5.07095C3.78983 4.88846 3.93784 4.74045 4.12033 4.74045H5.59021V3.93814C5.59021 3.75549 5.73807 3.60764 5.92056 3.60764C6.10306 3.60764 6.25092 3.75549 6.25092 3.93814V4.74045H7.72079C7.90329 4.74045 8.0513 4.88846 8.0513 5.07095C8.0513 5.25345 7.90329 5.40131 7.72079 5.40131H7.26547C7.19269 6.09924 6.89178 6.72989 6.43921 7.21832C6.81427 7.44324 7.25266 7.57263 7.72079 7.57263C7.8952 7.57263 8.03772 7.70782 8.05008 7.87918C8.64441 7.3262 9.44016 6.987 10.314 6.987H11.8411V5.59448L13.7924 4.20074C13.9462 4.09088 14.0376 3.91327 14.0376 3.72406C14.0376 3.5347 13.9462 3.35709 13.7924 3.24722L11.8176 1.8367C11.6632 0.798798 10.7661 0 9.68613 0H2.155C0.966644 0 0 0.966644 0 2.15485V9.68628C0 10.8745 0.966644 11.8411 2.155 11.8411H6.98715V10.3137Z"
                          fill="#97B6CF"
                        />
                        <path
                          d="M5.92041 6.80533C6.28204 6.43011 6.52756 5.9429 6.59943 5.40137H5.24155C5.31342 5.9429 5.55908 6.43011 5.92041 6.80533Z"
                          fill="#97B6CF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_587_259">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
                <button
                  onClick={(e) => saveButtonClicked(e)}
                  type="submit"
                  className="btn btn-primary"
                >
                  {t("Save Changes")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "like.svg"} alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p>{t("Language changed successfully")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ChangeLanguage;
