import axios from "axios";

const logout = async () =>{
     axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      await axios.post("/logout",{
        token: localStorage.getItem("loginToken")
       })
       
        localStorage.removeItem("loginToken");
        localStorage.removeItem("library_data");
        localStorage.removeItem("date");
        localStorage.removeItem("token-id");
        localStorage.removeItem("user_detail");
        localStorage.removeItem("username");
        window.location.href = "/login";
}
// For GET requests
const requestHelper = axios.create({
  baseURL: process.env.REACT_APP_API_LOCAL_KEY,
  headers: {
    "Content-Type": "application/json",
  },
});

requestHelper.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("loginToken");
    req.headers["Authorization"] = token;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// For POST requests
requestHelper.interceptors.response.use(
  (res) => {
    return res;
  },
  async(err) => {
    switch (err?.response?.status) {
      case 401:
       await logout() 
        return ;
        // break;
      default:
        break;
    }
    // return ;
    return Promise.reject(err);
  }
);

export default requestHelper;
