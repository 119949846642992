import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, Navigate } from "react-router";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import { loader } from "../loader";
const Model = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const [consent, setConsent] = useState("");
  const [count, setCount] = useState(0);
  const [checkbox1, setCheckBox1] = useState("");
  const [checkbox2, setCheckBox2] = useState("");
  const [checkbox3, setCheckBox3] = useState("");
  const [checkboxMain, setCheckMain] = useState(false);

  const checkbox1value = useRef(null);
  const checkbox2value = useRef(null);
  const checkbox3value = useRef(null);
  const checkboxvalue = useRef(null);

  const [showMinimumPopup, setShowMinimumPopup] = useState(false);
  const [checkboxObj, setCheckboxObj] = useState({});


  const [checkbox2Selected, setCheckbox2Selected] = useState(false);
  const [checkbox3Selected, setCheckbox3Selected] = useState(false);
  const [mandatoryCheck, setMandatoryCheck] = useState(false);
  
  const renderHTML = (escapedHTML) =>

    React.createElement("p", {
      id:"consentSettingPopup" ,
      dangerouslySetInnerHTML: { __html:t(escapedHTML) },

    });

  const [data, setData] = useState({});
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const handleClick = (e) => {
     let obj = {...checkboxObj}
    if(e.target.name == "bothOption"){
      obj.checkbox4 = true
      obj.checkbox5 = true
      obj.bothOption = true
      obj.noneOption = false
    }else if(e.target.name == "noneOption" ){
      obj.checkbox4 = false
      obj.checkbox5 = false
      obj.bothOption = false
      obj.noneOption = true
    }else{
      obj= {
        ...checkboxObj,
        [e.target.name]:e.target.checked,
        bothOption:false,
        noneOption : false
      }
      if(obj?.checkbox4 && obj?.checkbox5){
         obj.bothOption = true
      }
    }
    setCheckboxObj(obj)
    // name="noneOption"
    // if (e.target.name == "checkbox3") {
    //   setMandatoryCheck(true);
    //   if (mandatoryCheck) {
    //     setShowMinimumPopup(true);
    //     return;
    //   }
    // }
    // if (e.target.name == "checkbox4") {
    //   if (e.target.checked) {
    //     setCheckbox2Selected(true);
    //   } else {
    //     setCheckbox2Selected(false);
    //   }
    // }
    // if (e.target.name == "checkbox5") {
    //   if (e.target.checked) {
    //     setCheckbox3Selected(true);
    //   } else {
    //     setCheckbox3Selected(false);
    //   }
    // }
    // let totalValue;
    // if (e.target.checked) {
    //   totalValue = count + 1;
    // } else {
    //   totalValue = count - 1;
    // }

    // if (totalValue < 3) {
    //   setConsent("Limited consent");
    //   setCheckMain(false);
    // } else {
    //   setConsent("Full Consent");
    //   setCheckMain(true);
    // }
    // // totalValue < 3 ? {
    // //   setConsent("Limited consent"),
    // //  setCheckMain(false) }:( setConsent("Full Consent"),
    // //  setCheckMain(true));
    // setCount(totalValue);
  };
  // const handleOnClick = (e) =>{
  //   console.l

  // }
  const handleSubmit = async () => {
    let checkboxValue = "checkbox3";
    // if (!mandatoryCheck) {
    //   setShowMinimumPopup(true);
    //   return;
    // }
     if(checkbox1value.current.checked){
      checkboxValue += "~" + checkbox1value.current.name;
     }
    if (checkbox2value.current.checked) {
      checkboxValue += "~" + checkbox2value.current.name;
    }
    if (checkbox3value.current.checked) {
      checkboxValue += "~" + checkbox3value.current.name;
    }
    console.log("----dfdf",checkboxValue)
    try {
      let pdf_id = props?.code?.data.map(item => item.pdf_id) 
        // if( props?.code?.data.length == 1){
        //   pdf_id = props?.code?.data.map(item => item.pdf_id) 
        //   // props?.code?.data?.[0]?.pdf_id
        // }else {
        //   pdf_id = props?.code?.data.map(item => item.pdf_id)
        // }
      let body = {
        LangCode: i18n.language,
        pdfID: pdf_id,
        // props?.code?.data?.[0]?.pdf_id,
        consent_option: checkboxValue,
        method: "UserConsentStatusWeb",
        // emailid: localStorage.getItem("username"),
        Device_used: "Web",
      };
      // // axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show")
      const res = await postData(ENDPOINT.ADDCODE, body);
      loader("hide")
    } catch (err) {
      console.log(err);
    }
    props.handleClose();
  };
  const handleFun = (e) => {
    if (e.target.checked) {
      if (mandatoryCheck) {
        setCount(2);
      } else {
        setCount(3);
      }
      setCheckMain(true);
      setConsent("Full Consent");
      setCheckbox3Selected(true);
      setCheckbox2Selected(true);
      setMandatoryCheck(true);
    } else {
      setCheckMain(false);
      setConsent("Limited consent");
      setCheckbox3Selected(false);
      setCheckbox2Selected(false);
      setCount(1);
    }
  };
  useEffect(() => {
    setConsent(props.code?.ConsentSetting_radio);
  }, [props.code]);
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="consent-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="consent-data">
          <img
            className="consent-data-logo"
            src={path_image + "one-source-logo.svg"}
            alt="One Soource"
          />
          {/* <h3 id="consent_text" style={{ display: "block" }}>
            Consent Options
          </h3> */}
          { renderHTML(props?.code?.ConsentSetting_popup)}
          {/* <p id="consentSettingPopup" dangerouslySetInnerHTML={{ __html:props?.code?.ConsentSetting_popup}}> */}
            {/* {t(props?.code?.ConsentSetting_popup?.replaceAll("<br>", ""))} */}
          {/* </p> */}

          <div className="consent-form">
            <form
              id="hcp-form"
              method="post"
              name="frm_login"
              novalidate="novalidate"
            >
              <p>
                <strong>{t("I consent to receive")}</strong> (
                {t("tick all that apply")}):
              </p>
              <div className="form-group">
                <div className="radio">
                  <div className="form-group Consent">
                    <div className="form-group-left form-check">
                      {/* <input
                        type="checkbox"
                        id="fullconsent1"
                        // value={checkboxMain}
                        checked={checkboxMain ? true : false}
                        name="fullconsent"
                        ref={checkboxvalue}
                        className="form-check-input"
                        onClick={handleFun}
                      />
                      <label for="fullconsent1" id="consent-label">
                        {t(consent)}
                      </label> */}
                      <div className="options">
                        <div className="option-list">
                          <label id="checkbox1">
                            <input
                              type="checkbox"
                              checked={checkboxObj?.checkbox4 ? true : false}
                              onChange={handleClick}
                              name="checkbox4"
                              ref={checkbox1value}
                              label={props?.code?.checkbox1}
                              className="form-check-input consent-radio-box"
                              id="check1"
                            />
                            {t("Receive One Source updates and new materials from Octapharma.")}
                          </label>
                        </div>
                        <div className="option-list">
                          <label id="checkbox2">
                            <input
                              type="checkbox"
                              checked={checkboxObj?.checkbox5 ? true : false}
                              onChange={handleClick}
                              name="checkbox5"
                              ref={checkbox2value}
                              label={props?.code?.checkbox2}
                              className="form-check-input consent-radio-box"
                              id="check2"
                            />
                            {t("Receive invitations to future events.")}
                          </label>
                        </div>
                        <div className="option-list">
                          <label id="checkbox3">
                            <input
                              type="checkbox"
                              checked={checkboxObj?.bothOption ? true : false}
                              onChange={handleClick}
                              name="bothOption"
                              ref={checkbox3value}
                              label={props?.code?.checkbox3}
                              className="form-check-input consent-radio-box"
                              id="check3"
                            />
                            {t("Both of the options above.")}
                          </label>
                        </div>
                        <div className="option-list">
                          <label id="checkbox4">
                            <input
                              type="checkbox"
                              checked={checkboxObj?.noneOption ? true : false}
                              onChange={handleClick}
                              name="noneOption"
                              ref={checkbox3value}
                               label={props?.code?.checkbox3}
                              className="form-check-input consent-radio-box"
                              id="check4"
                            />
                            {t("None of the options above.")}
                          </label>
                        </div>

                        {/* <!-- <a href="javascript:void();" className="slec_op" id="close_consent" style="float: right;">Done </a> --> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form_btns">
                  <button
                    className="sumit-btn"
                    type="button"
                    onClick={handleSubmit}
                    id="signup_submit"
                  >
                    {t("I Consent")}
                  </button>
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={props.handleClose}
                    id="cancel_submit"
                    data-dismiss="modal"
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="terms-links" style={{ display: "block" }}>
            <ul>
              <li>
                <a href="/docintel-terms" target="_blank">
                  {t("Terms of use")}
                </a>
              </li>
              <li>
                <a href="/docintel-privacy" target="_blank">
                  {t("Privacy Policy")}
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showMinimumPopup}
        onHide={() => {
          setShowMinimumPopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "alert.svg"} alt="" />
            </div>
            <h6>{t("Minimum consent required.")}</h6>
            <p>
              {t(
                "Compilance require us to track who our readers are Please register and consent to at least the Docintel ToU & Privacy setting."
              )}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowMinimumPopup(false);
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default Model;
