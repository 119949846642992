import React from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { loader } from "../loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Forgot = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const [failStatus, showFailStatus] = useState(false);
  const navigate = useNavigate();
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const [status, setStatus] = useState(false);
  const [validator] = React.useState(new SimpleReactValidator());
  const [email, setEmail] = useState("");
  const emailChanged = (e) => {
    setEmail(e.target.value);
  };
  const handleValidation = (data) => {
    return (
      <div className="srv-validation-message">
        {" "}
        {t(`${data?.props?.children}`)}
      </div>
    );
  };

  const sendButtonClicked = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      let body = {
        email: email,
        LangCode: i18n.language,
      };

      loader("show");
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      await axios
        .post(`forget-password`, body)
        .then((res) => {
          if (res.data.frgtpassword.success == true) {
            setStatus(true);
            showFailStatus(false);
            loader("hide");
          } else {
            setStatus(false);
            showFailStatus(true);
            loader("hide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.showMessages();
      setRenderAfterValidation(renderAfterValidation + 1);
    }
  };

  return (
    <div className="page-background">
      <div className="login-sec">
        {status == false ? (
          <div className="forgot-mail">
            <h6 className="forgot">{t("Forgot password?")}</h6>
            <p className="forgot-title">
              {t("Please enter your email address to reset your password")}
            </p>
            <Form className="forgot_form_added">
              <Form.Group
                className="mb-3 password_forgot"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  value={email}
                  className={
                    validator.message("email", email, "required")
                      ? "validationErrors"
                      : ""
                  }
                  placeholder={t("Email")}
                  onChange={(e) => emailChanged(e)}
                />
                {validator.message("email", email, "required|email")
                  ? handleValidation(
                      validator.message("email", email, "required|email")
                    )
                  : ""}

                <span>
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9333 1.77222L10.7048 6.87521C10.4916 6.98865 10.2503 7.04833 10.0048 7.04833C9.7592 7.04833 9.5179 6.98865 9.30476 6.87521L0.0666666 1.77222C0.0225076 1.94943 0.000141946 2.13073 0 2.31264V10.9771C0 11.5903 0.261887 12.1785 0.728049 12.6121C1.19421 13.0458 1.82646 13.2894 2.48571 13.2894H17.5143C18.1735 13.2894 18.8058 13.0458 19.2719 12.6121C19.7381 12.1785 20 11.5903 20 10.9771V2.31264C19.9999 2.13073 19.9775 1.94943 19.9333 1.77222Z"
                      fill="#97B6CF"
                    />
                    <path
                      d="M10.2285 6.13954L19.5428 0.983389C19.314 0.680352 19.0103 0.432858 18.6573 0.261728C18.3043 0.0905987 17.9122 0.000838778 17.5142 0H2.4856C2.08753 0.000838778 1.6955 0.0905987 1.34249 0.261728C0.989475 0.432858 0.685807 0.680352 0.457031 0.983389L9.78084 6.13954C9.84948 6.17443 9.92644 6.19272 10.0047 6.19272C10.0829 6.19272 10.1598 6.17443 10.2285 6.13954Z"
                      fill="#97B6CF"
                    />
                  </svg>
                </span>
              </Form.Group>
              <div className="form-btns">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => sendButtonClicked(e)}
                >
                  {t("Send")}
                </Button>
                <Button
                  variant="primary"
                  className="signup"
                  type="submit"
                  onClick={(e) => navigate("/")}
                >
                  {t("Cancel")}
                </Button>
              </div>
              
            </Form>
          </div>
        ) : null}
        {status == true ? (
          <div className="get-confirm-mail">
            <img src="images/thanks-img.svg" alt="" />
            <h6> {t("You’ve Got Mail")}</h6>
            <p>
              {t(
                "Please check your email to complete the reset of your password"
              )}
            </p>
            <Button
              variant="primary"
              type="submit"
              onClick={() => navigate("/login")}
            >
              {t("Login")}
            </Button>
          </div>
        ) : null}
      </div>

      <Modal
        show={failStatus}
        onHide={() => {
          showFailStatus(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "alert.svg"} alt="" />
            </div>
            <h6>{t("This user doesn't exists.")}</h6>
            <p>{t("Something went wrong.")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                showFailStatus(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default Forgot;
