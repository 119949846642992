import React, { useEffect } from "react";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Navigate, useNavigate } from "react-router";
import axios from "axios";
import { loader } from "../loader";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import { useTranslation, Trans } from "react-i18next";
import ErrorModel from "./ErrorModel";
import "react-toastify/dist/ReactToastify.css";
const Feedback = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const [validator] = React.useState(
    new SimpleReactValidator({
      // element: message => <div>{message}</div>
      // OR
      element: (message, className = "validationError") => (
        <div className={className}>{message}</div>
      ),
      // })
    })
  );

  useEffect(() => {
    let userDetail = JSON.parse(localStorage.getItem("user_detail"));
    setUserName(userDetail?.email);
  }, []);
  // const getUserDetails = async () => {
  //   let body = {
  //     App_used: "docintel",
  //     Build_number: "1.0",
  //     Device_used: "Web",
  //     LangCode: "en",
  //     device_id: "web",
  //     token: localStorage.getItem("token-id"),
  //     method: "user_info",
  //   };
  //   loader("show");
  //   axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
  //   await axios
  //     .post(`get-profile`, body)
  //     .then((res) => {
  //       setUserName(res.data.data.docintel_login_usrid.username);
  //       loader("hide");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const handleValidation = (data) => {
    return (
      <div className="validationError"> {t(`${data?.props?.children}`)}</div>
    );
  };

  const saveButtonClicked = async (e) => {
    try {
      e.preventDefault();
      if (validator.allValid()) {
        let body = {
          method: "feedback",
          emailid: userName,
          feedback_content: feedback,
          LangCode: i18n.language,
        };
        loader("show");
        const res = await postData(ENDPOINT.FEEDBACK, body);
        axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;

        if (res.data.success == "true") {
          setShow(true);
          loader("hide");
        }

        // .catch((err) => {
        //   console.log(err);
        // });
      } else {
        validator.showMessages();
        setRenderAfterValidation(renderAfterValidation + 1);
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  };

  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div
          className="btn-back"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <button className="btn btn-primary btn-back">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                fill="#0066BE"
              ></path>
            </svg>
          </button>
        </div>
        <h6> {t("Feedback")}</h6>
      </div>
      <div className="user-profile">
        <div className="container">
          <div className="row">
            <div className="user-detail-inset feedback-sec">
              <h4>{t("Feedback")}</h4>
              <form>
                <div className="form-group">
                  <textarea
                    type="textarea"
                    onChange={(e) => {
                      setFeedback(e.target.value);
                    }}
                    rows="7"
                    className="form-control"
                    id="user_name"
                    placeholder={t("Tap to write your feedback")}
                    value={feedback}
                  />
                  {validator.message("feedback", feedback, "required")
                    ? handleValidation(
                        validator.message("feedback", feedback, "required")
                      )
                    : ""}
                </div>

                <button
                  onClick={(e) => saveButtonClicked(e)}
                  type="submit"
                  className="btn btn-primary"
                >
                  {t("Submit")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "like.svg"} alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p>{t("Feedback sent successfully!")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                navigate("/settings")
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </>
  );
};
export default Feedback;
