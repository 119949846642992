import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const Proctected = () => {
  let Token = localStorage.getItem("token-id");
  return Token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Proctected;
