import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Audio } from "react-loader-spinner";
import { loader } from "../loader";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";

const Content = () => {
  const navigate = useNavigate();
  const [updatedData, setUpdatedData] = useState([]);
  const [counter, setCounter] = useState();
  const [data, setData] = useState([]);
  const [cardClickedd, setCardClicked] = useState(false);
  const [journalTitle, setJournalTitle] = useState("");
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertSet, setAlertSet] = useState(false);
  const [abstract_data, setAbstractData] = useState("");
  const [share_url, setShareUrl] = useState("");
  const [filterProducts, setFilterProducts] = useState({});
  const [filterTags, setFilterTags] = useState({});
  const [filterFormat, setFilterFormat] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (flag) => {
    let body = {
      App_used: "docintel",
      Build_number: "1.0",
      Dvice_used: "Android",
      LangCode: "en",
      dte: "2022-07-26 05:13:07 +0000",
      device_id: "0b3a249288ca638c",
      emailid: "shiv.shinedezign@gmail.com",
      i: 1739633,
      ip_address: "192.168.10.16",
      lnk_click_count: "0",
      method: "refresh_data",
      oen_count: "1",
      time_spent: "12",
      timestamp: "null",
    };
    loader("show");
    await axios
      .post(`https://albert.docintel.app/web_services/`, body)
      .then((res) => {
        loader("hide");
        //  console.log(res.data.refresh_data.data);

        setFilterProducts(res.data.refresh_data.product);
        setFilterTags(res.data.refresh_data.tags);
        setFilterFormat(res.data.refresh_data.format);

        if (flag == 1) {
          const minilib = res.data.refresh_data.data.filter((data) => {
            return data.file_type == "mini_library";
          });
          //setData(minilib);

          const otherlib = res.data.refresh_data.data.filter((data) => {
            return data.file_type !== "mini_library";
          });

          const data = [...minilib, ...otherlib];
          setUpdatedData(data);

          setData(data);
        } else if (flag == 2) {
          const highClinicalData = res.data.refresh_data.data.filter((data) => {
            return data.cv >= 100;
          });
          setData(highClinicalData);
        } else if (flag == 3) {
          console.log(flag);
          const midClinicalData = res.data.refresh_data.data.filter((data) => {
            return data.cv >= 5 && data.cv <= 55;
          });

          console.log(midClinicalData);
          setData(midClinicalData);
        } else if (flag == 4) {
          const lowClinicalData = res.data.refresh_data.data.filter((data) => {
            return data.cv >= 0 && data.cv <= 5;
          });
          setData(lowClinicalData);
        } else if (flag == 5) {
          const openAccessType = res.data.refresh_data.data.filter((data) => {
            return data.access_type == "open";
          });
          setData(openAccessType);
        } else if (flag == 6) {
          const limtedAccessType = res.data.refresh_data.data.filter((data) => {
            return data.access_type == "limted";
          });
          setData(limtedAccessType);
        } else if (flag == 7) {
          const freeAccessType = res.data.refresh_data.data.filter((data) => {
            return data.access_type == "free";
          });
          setData(freeAccessType);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const alertButtonClicked = () => {
    setAlert(!alert);
    setAlertSet(!alertSet);
  };

  const settingsClicked = () => {
    setCounter(4);
  };

  const albertClicked = () => {
    navigate("/Albert");
    setCounter(1);
  };

  const libraryClicked = () => {
    setCounter(2);
  };

  const cpdClicked = () => {
    setCounter(3);
  };

  const logoutClicked = () => {
    localStorage.clear();
    navigate("/");
  };

  const cardClicked = (
    journal_title,
    title,
    authors,
    abstract_data,
    share_url,
    file_type,
    data
  ) => {
    console.log(file_type);
    //  console.log(data);

    if (share_url) {
      setCardClicked(false);
    } else if (file_type == "mini_library") {
      navigate("/MiniLibrary", {
        state: {
          data: data.sub_data,
          access_type: data.access_type,
          filterProducts: filterProducts,
          filterTags: filterTags,
          filterFormat: filterFormat,
          title: data.title,
        },
      });
    } else {
      setCardClicked(true);
      setJournalTitle(journal_title);
      setTitle(title);
      const myArray = authors.split("<br/>");

      setAuthors(myArray);
      setAbstractData(abstract_data);
      setShareUrl(share_url);
    }
  };
  const submitHandler = (event) => {
    event.preventDefault();
    let r_table = [];
    updatedData.find(function (item) {
      //   console.log(item);
      //  console.log(item.title);
      if (item.title !== undefined) {
        if (item.title.includes(search)) {
          r_table.push(item);
        }
      } else if (item.pdf_title !== undefined) {
        if (item.pdf_title.includes(search)) {
          r_table.push(item);
        }
      } else if (item.journal_title !== undefined) {
        if (item.journal_title.includes(search)) {
          r_table.push(item);
        }
      }
    });
    if (r_table.length > 0) {
      setData(r_table);
    }
    return false;
  };

  const searchChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setData(updatedData);
    }
  };

  return (
    <>
      {/* 
      {counter == 4 ? (
        <>
          <button className="btn btn-primary" onClick={logoutClicked}>
            Logout
          </button>
        </>
      ) : null}

      <div className="left-sidebar">
        <div>
          <a className="btn btn-primary" onClick={() => getData(1)}>
            All articles
          </a>
          <a className="btn btn-primary" onClick={() => getData(2)}>
            High Clinical Value
          </a>
          <a className="btn btn-primary" onClick={() => getData(3)}>
            Mid Clinical Value
          </a>
          <a className="btn btn-primary" onClick={() => getData(4)}>
            Low Clinical Value
          </a>
        </div>

        <div className="access-container">
          <a className="btn btn-primary" onClick={() => getData(1)}>
            All
          </a>
          <a className="btn btn-primary" onClick={() => getData(5)}>
            Open Access
          </a>
          <a className="btn btn-primary" onClick={() => getData(6)}>
            Limited Access
          </a>
          <a className="btn btn-primary" onClick={() => getData(7)}>
            Free Access
          </a>
        </div>
      </div>

      <div className="col right-sidebar">
        {data !== "undefined" && data.length > 0 ? (
          data.map((data) => {
            return (
              <>
                <a
                  href={data.share_url}
                  className="card text-center card"
                  target="_blank"
                  onClick={() =>
                    cardClicked(
                      data.journal_title,
                      data.title,
                      data.author,
                      data.abstract_data,
                      data.share_url,
                      data.file_type,
                      data
                    )
                  }
                >
                  <div className="card-header pdf-title">
                    {" "}
                    {data.journal_title || data.pdf_sub_title}
                  </div>
                  <img
                    className="docintel-images"
                    src={
                      data.pdf_thumb ||
                      data.img_url ||
                      data.relevant_specialty_image
                    }
                    alt="image"
                  ></img>
                  <div className="display-content">
                    {data.cv ? (
                      <>
                        {" "}
                        <p
                          style={{
                            backgroundColor: data.cv_color,
                          }}
                        >
                          CV {data.cv}
                        </p>
                        <p> {data.access_type} access </p>
                      </>
                    ) : null}

                    <h5 className="card-title"> </h5>
                    <div className="card-body">
                      <h5 className="card-text">
                        {data.title || data.pdf_title}
                      </h5>
                    </div>
                  </div>
                </a>
              </>
            );
          })
        ) : (
          <h1 className="no-data-found">No data found</h1>
        )}
      </div>

      <Modal
        show={cardClickedd}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          {journalTitle}
          <button
            onClick={() => {
              setCardClicked(false);
            }}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p style={{ backgroundColor: "#3972b7" }}>{title}</p>
          <br />
          <button
            className="btn btn-primary alert-button"
            onClick={alertButtonClicked}
          >
            Get Alert
          </button>

          <p className="model-title">Authors:</p>
          {authors.map((authors) => {
            return <span>{authors},</span>;
          })}
          <br />
          <br />
          <p className="model-title">Abstract:</p>
          {abstract_data}
          <br />
        </Modal.Body>
      </Modal>

      <Modal
        show={alert}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <button
            onClick={() => {
              setAlert(false);
            }}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          {alertSet == true ? (
            <p>
              Thanks. I will alert you as soon as the article becomes available.
            </p>
          ) : (
            <p>Alert for the article removed successfully</p>
          )}
        </Modal.Body>
      </Modal> */}
    </>
  );
};
export default Content;
