import React from "react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { loader } from "../loader";
import axios from "axios";

import { useTranslation, Trans } from "react-i18next";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import ErrorModel from "./ErrorModel";
const Avtar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [imageIndex, setImageIndex] = useState();
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const navigate = useNavigate();
  const letsStartClicked = async () => {
    try {
      let body = {
        App_used: "docintel",
        Build_number: "1.0",
        Device_used: "Web",
        LangCode: "en",
        device_id: "web",
        //  token: localStorage.getItem("token-id"),
        image: imageIndex,
        name: location.state.name,
        LangCode: i18n.language,
      };
      // axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      const res = await postData(ENDPOINT.EDITPROFILE, body);
      loader("show");

      if (res.status == 200 || res.status == 201) {
        loader("hide");
      }
      let userDetail = JSON.parse(localStorage.getItem("user_detail"));
      userDetail.image = imageIndex;
      localStorage.setItem("user_detail", JSON.stringify(userDetail));
      navigate("/library");
    } catch (err) {
      console.log(err);
      loader("hide");
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  };

  const [active, setActive] = useState("");

  return (
    <div className="page-background avatar">
      <div className="login-sec avtar-sec">
        <h4>{t("Customize Your Profile")}</h4>
        <p>
          {t(
            "Please choose your Avatar, and if you want to keep the default click “let’s start”"
          )}
        </p>
        <div className="default-avtar">
          <div className="avtar-box" onClick={() => setImageIndex(0)}>
            <img
              src={
                imageIndex == 0
                  ? path_image + "doctor-5.svg"
                  : imageIndex == 1
                  ? path_image + "doctor-man.svg"
                  : imageIndex == 2
                  ? path_image + "doctor-women.svg"
                  : imageIndex == 3
                  ? path_image + "doctor-3.svg"
                  : imageIndex == 4
                  ? path_image + "doctor-4.svg"
                  : imageIndex == 5
                  ? path_image + "nurse.svg"
                  : path_image + "doctor-5.svg"
              }
              alt=""
            />
          </div>
        </div>
        <div className="avtar-list">
          <div className="avtar-box">
            <ul>
              <li>
                <div
                  onClick={() => setImageIndex(1)}
                  className={
                    active == "doctor-man"
                      ? "avtar-list-img active"
                      : "avtar-list-img"
                  }
                >
                  <img
                    src={path_image + "doctor-man.svg"}
                    onClick={() => {
                      console.log("hii");
                      localStorage.setItem("avtar-img", "doctor-man.svg");
                      setActive("doctor-man");
                    }}
                    alt=""
                  />
                </div>
              </li>
              <li>
                <div
                  onClick={() => setImageIndex(2)}
                  className={
                    active == "doctor-women"
                      ? "avtar-list-img active"
                      : "avtar-list-img"
                  }
                >
                  <img
                    src={path_image + "doctor-women.svg"}
                    alt=""
                    onClick={() => {
                      localStorage.setItem("avtar-img", "doctor-women.svg");
                      setActive("doctor-women");
                    }}
                  />
                </div>
              </li>
              <li>
                <div
                  onClick={() => setImageIndex(5)}
                  className={
                    active == "nurse"
                      ? "avtar-list-img active"
                      : "avtar-list-img"
                  }
                >
                  <img
                    src={path_image + "nurse.svg"}
                    onClick={() => {
                      localStorage.setItem("avtar-img", "nurse.svg");
                      setActive("nurse");
                    }}
                    alt=""
                  />
                </div>
              </li>
              <li>
                <div
                  onClick={() => setImageIndex(3)}
                  className={
                    active == "doctor-3"
                      ? "avtar-list-img active"
                      : "avtar-list-img"
                  }
                >
                  <img
                    src={path_image + "doctor-3.svg"}
                    alt=""
                    onClick={() => {
                      localStorage.setItem("avtar-img", "doctor-3.svg");
                      setActive("doctor-3");
                    }}
                  />
                </div>
              </li>
              <li>
                <div
                  onClick={() => setImageIndex(4)}
                  className={
                    active == "doctor-4"
                      ? "avtar-list-img active"
                      : "avtar-list-img"
                  }
                >
                  <img
                    src={path_image + "doctor-4.svg"}
                    alt=""
                    onClick={() => {
                      localStorage.setItem("avtar-img", "doctor-4.svg");
                      setActive("doctor-4");
                    }}
                  />
                </div>
              </li>
              <li>
                <div
                  onClick={() => setImageIndex(0)}
                  className={
                    active == "doctor-5"
                      ? "avtar-list-img active circle_img"
                      : "avtar-list-img circle_img"
                  }
                >
                  <img
                    src={path_image + "doctor-5.svg"}
                    alt=""
                    onClick={() => {
                      localStorage.setItem("avtar-img", "doctor-5.svg");
                      setActive("doctor-5");
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
          <Button onClick={letsStartClicked} variant="primary">
            {t("Let's Start")}
          </Button>
        </div>
      </div>
      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </div>
  );
};

export default Avtar;
