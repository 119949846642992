const NEW_VERSION = "new-version";
export const ENDPOINT = {
  LIBRARY: `${NEW_VERSION}/library/get-library`,
  LOGIN: "login",
  ANDROIDALBERT:"android-albert-listing",
  TOTALARTICLE: "total-article",
  LOGOUT: "logout",
  SIGNUP: "signup",
  NEWLOGIN: "new-login",
  NEWDIRECTLOGIN: "new-direct-login",
  ALBERTMAINCATEGORY: `${NEW_VERSION}/albert/mainCategory`,
  USERUPDATEPROFILE: "user-update-profile",
  ALBERTLISTING: `${NEW_VERSION}/albert/get-ablert-listing`,
  ALBERTRATING: `${NEW_VERSION}/albert/rating`,
  ALBERTARTICLESTATUS: `${NEW_VERSION}/albert/user-article-status`,
  ALBERTCUSTOMSPECIALITY: `${NEW_VERSION}/albert/custom-speciality`,
  ALBERTSHAREARTICLEDOC: `${NEW_VERSION}/albert/index`,
  ALBERTSAVED: `${NEW_VERSION}/albert/saved`,
  GETCODEDATA: `${NEW_VERSION}/albert/get-code-data`,
  ADDCODE: `${NEW_VERSION}/albert/add-code`,
  ADDTRACKING: `${NEW_VERSION}/albert/add-tracking`,
  DELETESAVEDLIBRARY: `${NEW_VERSION}/albert/delete-saved-library`,
  DELETESAVEDLIBRARY_ANDROID: `/delete-saved-library`,

 
  DELETEALERT: `${NEW_VERSION}/albert/delete-alert`,
  DELETEALERT_ANDROID: `/delete-alert`,

  ADDALERT: `${NEW_VERSION}/albert/add-alert`,
  ADDALERT_ANDROID: `/add-alert`,
  EDITPROFILE: "edit-profile",
  CHANGEPASSWORD: "change-password",
  REQUESTMATERIALS: `${NEW_VERSION}/library/material-index`,
  GETTRACKING: `${NEW_VERSION}/albert/get-tracking`,
  GETPROFILE: "get-profile",
  CONSENTSETTING: "consent-setting",
  GETCONSENTSETTING: "get-consent-setting",
  FEEDBACK: "feedback",
  SHAREALBERT: `/share-albert-detail/`,

};
