import React from "react";
import { loader } from "../loader";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { postData } from "../axios/apiHelper";
import { useTranslation, Trans } from "react-i18next";

import { ENDPOINT } from "../axios/apiConfig";
import ErrorModel from "./ErrorModel";

const ReadingTracker = () => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [recordFound, setRecordFound] = useState(false);
  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    try {
      let body = {
        App_used: "docintel",

        Build_number: "1.0",

        Device_used: "web",

        method: "cpd_tracking",

        device_id: "web",

        //  emailid: localStorage.getItem("username"),

        BrowserArchiveTimeTracking: {
          //user_id: localStorage.getItem("token-id"),
          data: [],
        },

        LangCode: i18n.language,
      };
      loader("show");

      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      const res = await postData(ENDPOINT.GETTRACKING, body);

      if (res.status == 200) {
        if (res.data != null) {
          setRecordFound(true);
          setData(res.data.cpd_tracking.cdp_tracks);
          loader("hide");
        } else {
          setRecordFound(true);
          loader("hide");
        }
      }
    } catch (err) {
      loader("hide");
      console.log(err);
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }

    // .catch((err) => {
    //   console.log(err);
    // });
  };

  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div
          className="btn-back"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <button className="btn btn-primary btn-back">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                fill="#0066BE"
              ></path>
            </svg>
          </button>
        </div>
        <h6>{t("Viewed contents")}</h6>
      </div>
      <div className={"library-sec"}>
        <Container fluid={true}>
          <Row>
            <Col className="albert-left-nav">
              <div></div>
            </Col>

            <Col xs={6} className="tabs-menu">
              <div className="reading_tracker_list">
                {data !== "undefined" && data?.length > 0 ? (
                  data.map((data, index) => {
                    return (
                      <>
                        <div className="library-article-list">
                          <div className="inner_cart_section">
                            <div
                              className={
                                data.type !== "ncbi"
                                  ? "left_section_carts medar"
                                  : "left_section_carts"
                              }
                            >
                              <ul>
                                <li className="list-inline-item">
                                  <a className="clickable_cards">
                                    <div className="img_border">
                                      <img src={data?.cover} alt="Article" />
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="right_section_carts clickable_cards">
                              <div className="right_section_carts_top_title">
                                <h6 className="inner_heading_crt">
                                  <a className="clickable_cards">
                                    <div>{data?.pdf_title}</div>
                                  </a>
                                </h6>
                              </div>
                              <a className="clickable_cards">
                                <div className="categoty_title">
                                  {data?.pdf_sub_title}
                                </div>
                              </a>
                              <div className="start_time">
                                <span>{t("Start reading")}</span>{" "}
                                {data.starttime !== 0
                                  ? moment(data.starttime).format(
                                      "MMMM DD ,YYYY"
                                    )
                                  : "N/A"}
                              </div>
                              <div className="start_time">
                                <span>{t("Time spent")}</span>{" "}
                                {data?.total_time_spent !== "00:00:00" &&
                                data?.total_time_spent !== "0:00:00" ? (
                                  data?.total_time_spent?.split(":")[0] !==
                                    "00" &&
                                  data?.total_time_spent?.split(":")[0] !==
                                    "0" ? (
                                    data?.total_time_spent?.split(":")[0] + "h"
                                  ) : data?.total_time_spent?.split(":")[1] !==
                                    "00" ? (
                                    data?.total_time_spent?.split(":")[1] + "m"
                                  ) : data?.total_time_spent?.split(":")[2] !==
                                    "00" ? (
                                    data?.total_time_spent?.split(":")[2] + "s"
                                  ) : null
                                ) : (
                                  <>NA</>
                                )}
                              </div>

                              {/* <div className="categoty_view">
                      <a
                        className="clickable_cards"
                        onClick={() => {
                          setModelLibraryData(data);
                          abstractViewClicked(index, data);
                        }}
                      >
                        <svg
                          width="16"
                          height="10"
                          viewBox="0 0 16 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0.231201C4.94332 0.231201 2.17198 1.90057 0.125448 4.61943C-0.0418161 4.84245 -0.0418161 5.15074 0.125448 5.37376C2.17198 8.09591 4.94332 9.76855 8 9.76855C11.0567 9.76855 13.828 8.09919 15.8745 5.38032C16.0418 5.1573 16.0418 4.84901 15.8745 4.62599C13.828 1.90385 11.0567 0.231201 8 0.231201ZM8.21974 8.35829C6.18961 8.48619 4.51696 6.81355 4.64487 4.78342C4.74982 3.11077 6.10761 1.7497 7.78354 1.64475C9.81367 1.51684 11.4863 3.18949 11.3584 5.21962C11.2502 6.89226 9.89238 8.25006 8.21974 8.35829Z"
                            fill="#97B6CF"
                          ></path>
                          <path
                            d="M8.11809 6.8069C7.02595 6.87577 6.12404 5.97385 6.19291 4.88171C6.24866 3.97979 6.98004 3.24842 7.88195 3.19267C8.97409 3.12379 9.87601 4.02571 9.80714 5.11785C9.75138 6.01977 9.02001 6.75114 8.11809 6.8069Z"
                            fill="#97B6CF"
                          ></path>
                        </svg>
                      </a>
                    </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    {recordFound == true ? <h3>{t("No data found")}</h3> : null}
                  </>
                )}
              </div>
            </Col>
            <Col className="library-right-nav"></Col>
          </Row>
        </Container>
      </div>

      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </>
  );
};
export default ReadingTracker;
