import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n/config";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Content from "./components/Content";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/common components/Header";
import MiniLibrary from "./components/MiniLibrary";
import Albert from "./components/Albert";
import Settings from "./components/Settings";
import EditProfile from "./components/EditProfile";
import ChangePassword from "./components/ChangePassword";
import ChangeLanguage from "./components/ChangeLanguage";
import Feedback from "./components/Feedback";
import ConsentSettings from "./components/ConsentSettings";
import Privacy from "./components/Privacy";
import TermsOfUse from "./components/TermsOfUse";
import Proctected from "./Protected";
import Login from "./components/Login";
import Signup from "./components/Signup";

import Avtar from "./components/Avtar";
import Library from "./components/Library";
import DocintelPrivacy from "./components/DocintelPrivacy";
import DocintelTerms from "./components/DocintelTerms";
import Forgot from "./components/Forgot";
import PrivacySetting from "./components/PrivacySetting";
import QuickTutorial from "./components/QuickTutorial";
import AlbertLibrary from "./components/AlbertLibrary";
import NcbiLibrary from "./components/NcbiLibrary";
import Digest from "./components/Digest";
import PrivacySettings2 from "./components/PrivacySettings2";
import QuickTutorial2 from "./components/QuickTutorial2";
import ReadingTracker from "./components/ReadingTracker";
import Filter from "./components/Filter";
import ResetPassword from "./components/ResetPassword";
import DigestRegister from "./components/DigestRegister";
import Setpassword from "./components/Setpassword";
import ShareAlbert from "./components/ShareAlbert";
import RedirectAlbert from "./components/Redirect"
import OctapharmaPrivacy from "./components/OctapharmaPrivacy"

import OctapharmaTrailPrivacy from "./components/OctapharmaTrailPrivacy"

import axios from "axios";

import { useNavigate } from "react-router-dom";

require("./components/assets/style1.css");
require("./components/assets/style.css");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Header />
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/octapharma-privacy" element={<OctapharmaPrivacy />} />
      <Route exact path="/octapharma-trail-privacy" element={<OctapharmaTrailPrivacy />} />

      <Route exact path="/redirect" element={<RedirectAlbert />} />
      <Route exact path="/share-albert/:id" element={<ShareAlbert />} />
      <Route exact path="/digest-register" element={<DigestRegister />} />
      <Route element={<Proctected />}>
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/MiniLibrary" element={<MiniLibrary />} />

        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/EditProfile" element={<EditProfile />} />
        <Route exact path="/ChangePassword" element={<ChangePassword />} />
        <Route exact path="/ChangeLanguage" element={<ChangeLanguage />} />
        <Route exact path="/ReadingTracker" element={<ReadingTracker />} />

        <Route exact path="/Feedback" element={<Feedback />} />
        <Route exact path="/ConsentSettings" element={<ConsentSettings />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/Termsofuse" element={<TermsOfUse />} />
        <Route exact path="/AlbertLibrary" element={<AlbertLibrary />} />
        <Route exact path="/NcbiLibrary" element={<NcbiLibrary />} />
        <Route exact path="/Avtar" element={<Avtar />} />
        <Route exact path="/Library" element={<Library />} />
      </Route>
      <Route exact path="/Albert" element={<Albert />} />

      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/Login" element={<Login />} />
      <Route exact path="/privacy_policy" element={<DocintelPrivacy />} />
      <Route exact path="/terms_of_use" element={<DocintelTerms />} />

      <Route exact path="/forgot-password" element={<Forgot />} />
      <Route exact path="/privacy-setting" element={<PrivacySetting />} />
      <Route exact path="/docintel-privacy" element={<DocintelPrivacy />} />
      <Route exact path="/docintel-terms" element={<DocintelTerms />} />

      <Route exact path="/tutorial" element={<QuickTutorial />} />
      <Route exact path="/digest" element={<Digest />} />
      <Route exact path="/PrivacySettings2" element={<PrivacySettings2 />} />
      <Route exact path="/QuickTutorial2" element={<QuickTutorial2 />} />
      <Route exact path="/filter" element={<Filter />} />
      <Route exact path="/ResetPassword" element={<ResetPassword />} />
      <Route exact path="/PasswordGenerate" element={<Setpassword />} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
