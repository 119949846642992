import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../axios/apiHelper";
import { ENDPOINT } from "../axios/apiConfig";
import { useTranslation, Trans } from "react-i18next";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const arr = [
    "doctor-5.svg",
    "doctor-man.svg",
    "doctor-women.svg",
    "doctor-3.svg",
    "doctor-4.svg",
    "nurse.svg",
  ];
  const navigate = useNavigate();
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const [name, setName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [imageIndex, setImageIndex] = useState();
  const [digest_status, setDigestStatus] = useState();

  const logoutUser = async () => {
    try {
      const res = await postData(ENDPOINT.LOGOUT, {
        token: localStorage.getItem("loginToken"),
      });

      localStorage.removeItem("token-id");
      localStorage.removeItem("username");
      localStorage.removeItem("user_detail");
      localStorage.removeItem("library_data");
      localStorage.removeItem("date");
      localStorage.removeItem("loginToken");

      navigate("/");
    } catch (err) {
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  };

  useEffect(() => {
    let userDetail = JSON.parse(localStorage.getItem("user_detail"));
    setDigestStatus(userDetail?.digest_status);
    //console.log(userDetail?.image);
    setImageIndex(userDetail?.image);
    setName(userDetail?.name);
    setCountry(userDetail?.country);
    setSpeciality(userDetail?.speciality);
  }, []);

  return (
    <>
      <body>
        <div className="mobile-header" style={{ display: "none" }}>
          <h6> {t("User")}</h6>
        </div>
        <div className="wrapper">
          <div
            className={
              i18n.language == "en"
                ? "user_library english"
                : i18n.language == "ru"
                ? "user_library russian"
                : i18n.language == "es"
                ? "user_library spanish"
                : "user_library"
            }
          >
            <div className="container">
              <div className="row">
                <div className="user-library-inset">
                  <div className="user-library-user-view">
                    <div className="user-library-user-img">
                      <img src={path_image + arr[imageIndex]} alt="" />
                    </div>
                    <div className="user-library-user-detail">
                      <h6> {t("Welcome")},</h6>
                      <h4>{name}</h4>
                      <p> {t(speciality)}</p>
                      <p>{country}</p>
                      {digest_status == 1 ? (
                        <a
                          className="library-logout-user"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/digest");
                          }}
                        >
                          {t("Digest Setting")}
                        </a>
                      ) : null}
                    </div>

                    <a
                      className="user-library-logout"
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      <div className="library-logout-user">
                        <img src={path_image + "logout-icons.svg"} alt="" />
                        {t("Logout")}
                      </div>
                    </a>
                  </div>
                  <div className="library-user-options">
                    <div className="library-user-options-list">
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => {
                              navigate("/EditProfile");
                            }}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "edit-profile-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Edit Profile")}</p>
                          </div>
                        </a>
                      </div>
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => {
                              navigate("/ChangePassword");
                            }}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "change-password-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Change Password")}</p>
                          </div>
                        </a>
                      </div>
                      <div
                        className="library-user-options-box"
                        onClick={() => {
                          navigate("/ChangeLanguage");
                        }}
                      >
                        <a>
                          <div className="library-user-options-box-inset">
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "change-language-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Change Language")}</p>
                          </div>
                        </a>
                      </div>
                      <div
                        className="library-user-options-box"
                        onClick={() => navigate("/ReadingTracker")}
                      >
                        <a>
                          <div className="library-user-options-box-inset">
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "reading-traker-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Viewed contents")}</p>
                          </div>
                        </a>
                      </div>
                      <div
                        className="library-user-options-box"
                        onClick={() => {
                          navigate("/ConsentSettings");
                        }}
                      >
                        <a>
                          <div className="library-user-options-box-inset">
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "consent-setting-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Consent Settings")}</p>
                          </div>
                        </a>
                      </div>
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => navigate("/Feedback")}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "feedback-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Feedback")}</p>
                          </div>
                        </a>
                      </div>
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => navigate("/Privacy")}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "privacy-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Privacy")}</p>
                          </div>
                        </a>
                      </div>
                      <div
                        className="library-user-options-box"
                        onClick={() => navigate("/Termsofuse")}
                      >
                        <a>
                          <div className="library-user-options-box-inset">
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "terms-of-use-img.png"}
                                alt=""
                              />
                            </div>
                            <p>{t("Terms of Use")}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    {/* <div className="library-user-options-list">
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => navigate("/Feedback")}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "feedback-img.png"}
                                alt=""
                              />
                            </div>
                            <p>Feedback</p>
                          </div>
                        </a>
                      </div>
                      <div className="library-user-options-box">
                        <a>
                          <div
                            className="library-user-options-box-inset"
                            onClick={() => navigate("/Privacy")}
                          >
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "privacy-img.png"}
                                alt=""
                              />
                            </div>
                            <p>Privacy</p>
                          </div>
                        </a>
                      </div>
                      <div
                        className="library-user-options-box"
                        onClick={() => navigate("/Termsofuse")}
                      >
                        <a>
                          <div className="library-user-options-box-inset">
                            <div className="library-user-options-box-img">
                              <img
                                src={path_image + "terms-of-use-img.png"}
                                alt=""
                              />
                            </div>
                            <p>Terms of Use</p>
                          </div>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Settings;
