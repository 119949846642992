import Modal from "react-bootstrap/Modal";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Content from "./Content";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";
import { loader } from "../loader";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import { login } from "../axios/apiHelper";

import { postData } from "../axios/apiHelper";
import { ENDPOINT } from "../axios/apiConfig";

import { useTranslation, Trans } from "react-i18next";
import { toast } from "react-toastify";
const Login = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const [validator] = React.useState(new SimpleReactValidator());
  const [rememberMe, setRememberMe] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  // const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("email") && localStorage.getItem("password")) {
      setRememberMe(true);
      setUser({
        ...user,
        email: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
      });
      // setEmail(localStorage.getItem("email"));
      // setPassword(localStorage.getItem("password"));
    }
  }, []);

  const rememberMeClicked = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (rememberMe == true) {
        localStorage.setItem("email", user?.email);
        localStorage.setItem("password", user?.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      if (validator.allValid()) {
        loader("show");
        const res = await postData(ENDPOINT.LOGIN, {
          email: user?.email,
          password: user?.password,
        });
        loader("hide");
        localStorage.setItem("token-id", res?.data?.data?.token);
        localStorage.setItem("username", res?.data?.data?.email);
        localStorage.setItem("encryped_id", res?.data?.data?.encryped_id);
        localStorage.setItem("loginToken", res?.data?.data?.loginToken);
        const userDetail = {
          email: res.data.data?.email,
          user_id: res?.data?.data?.user_id,
          speciality: res.data.data?.speciality,
          content_option: res?.data?.data?.content_option,
          image: res?.data?.data?.image,
          name: res?.data?.data?.name,
          digest_status: res?.data?.data?.digest_status,
          country: res?.data?.data?.country,
        };
        localStorage.setItem("user_detail", JSON.stringify(userDetail));
        navigate("/library");
        loader("hide");
      } else {
        validator.showMessages();
        setRenderAfterValidation(renderAfterValidation + 1);
      }
    } catch (err) {
      switch (err.request.status) {
        case 400:
          setShowPopup(true);
          setMessage(err?.response?.data?.message);
          loader("hide");
          break;
        case 500:
          setMessage(err?.response?.data?.message);
          setShowPopup(true);
          loader("hide");
          break;
        default:
          loader("hide");
          break;
      }
    }
  };
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };
  const handleValidation = (data) => {
    return (
      <div className="srv-validation-message">
        {" "}
        {t(`${data?.props?.children}`)}
      </div>
    );
  };

  return (
    <>
      <div className="page-background">
        <div className="login-sec">
          <h6>{t("Welcome To")}</h6>
          
            <h1><Link to={"/"}>  Docintel</Link>
            </h1> 
          
          <Form onSubmit={(e) => handleLogin(e)} className="login-form">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                name="email"
                value={user?.email}
                className={
                  validator.message("email", user?.email, "required|email")
                    ? "validationErrors"
                    : ""
                }
                placeholder={t("Email")}
                onChange={handleChange}
              />
              {validator.message("email", user?.email, "required|email")
                ? handleValidation(
                    validator.message("email", user?.email, "required|email")
                  )
                : ""}
              <span>
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9333 1.77222L10.7048 6.87521C10.4916 6.98865 10.2503 7.04833 10.0048 7.04833C9.7592 7.04833 9.5179 6.98865 9.30476 6.87521L0.0666666 1.77222C0.0225076 1.94943 0.000141946 2.13073 0 2.31264V10.9771C0 11.5903 0.261887 12.1785 0.728049 12.6121C1.19421 13.0458 1.82646 13.2894 2.48571 13.2894H17.5143C18.1735 13.2894 18.8058 13.0458 19.2719 12.6121C19.7381 12.1785 20 11.5903 20 10.9771V2.31264C19.9999 2.13073 19.9775 1.94943 19.9333 1.77222Z"
                    fill="#97B6CF"
                  />
                  <path
                    d="M10.2285 6.13954L19.5428 0.983389C19.314 0.680352 19.0103 0.432858 18.6573 0.261728C18.3043 0.0905987 17.9122 0.000838778 17.5142 0H2.4856C2.08753 0.000838778 1.6955 0.0905987 1.34249 0.261728C0.989475 0.432858 0.685807 0.680352 0.457031 0.983389L9.78084 6.13954C9.84948 6.17443 9.92644 6.19272 10.0047 6.19272C10.0829 6.19272 10.1598 6.17443 10.2285 6.13954Z"
                    fill="#97B6CF"
                  />
                </svg>
              </span>
            </Form.Group>

            <Form.Group className="mb-3 pass" controlId="formBasicPassword">
              <Form.Control
                type={showPassword == true ? "text" : "password"}
                value={user?.password}
                name="password"
                className={
                  validator.message("password", user?.password, "required")
                    ? "validationErrors"
                    : ""
                }
                placeholder={t("Password")}
                onChange={handleChange}
              />
              {/* {validator.message("password",  user?.password, "required")} */}
              {validator.message("password", user?.password, "required")
                ? handleValidation(
                    validator.message("password", user?.password, "required")
                  )
                : ""}

              <span>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                    fill="#97B6CF"
                  />
                </svg>
              </span>
              <span className="pawword_img">
                <img
                  src={
                    showPassword == true
                      ? path_image + "password-protected.png"
                      : path_image + "Union.png"
                  }
                  alt=""
                  onClick={showPasswordClicked}
                />
              </span>
            </Form.Group>
            <Form.Group className="mb-3 pass" controlId="formBasicCheckbox">
              <Form.Check
                onChange={(e) => {
                  rememberMeClicked(e);
                }}
                checked={rememberMe}
                type="checkbox"
                label={t("Remember me")}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {t("Login")}
            </Button>
            <div className="forgot-details">
              <Form.Label>
                <Link to={"/forgot-password"} style={{ margin: "0 0 0 6px" }}>
                  {t("Forgot password?")}
                </Link>
              </Form.Label>
              <Form.Label>
                {t("Don’t have an account?")}
                <Link to={"/signup"} style={{ margin: "0 0 0 6px" }}>
                  {t("Register")}
                </Link>
              </Form.Label>
            </div>
          </Form>
        </div>
      </div>

      <Modal
        show={showPopup}
        onHide={() => {
          setShowPopup(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "alert.svg"} alt="" />
            </div>
            <h6>{t(message)}</h6>
            <p></p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default Login;
