import logo from "./logo.svg";
import "./App.css";

import Footer from "./components/Footer1";
import { useEffect, useState } from "react";
import queryString from "query-string";

import { Button, Col, Container, Row, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loader } from "./loader";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { getData } from "./axios/apiHelper";
import { ENDPOINT } from "./axios/apiConfig";
import Header from "./components/common components/Header";
import Library from "./components/Library";

function App() {
  const [addScroll, setAddScroll] = useState(false);
  const [TotalArticle, setTotalArticle] = useState(0);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const queryParams = queryString.parse(window.location.search);
  useEffect(() => {
    if (localStorage.getItem("token-id")) {
      navigate("/library");
    }
  });

  useEffect(() => {
    getCountryLocation();
    totalArticle();
  }, []);

  const totalArticle = async () => {
    try {
      const result = await getData(ENDPOINT.TOTALARTICLE);
      const counting = result?.data?.data - 20000;
      if (counting) {
        for (let count = counting; count <= result?.data?.data; count++) {
          setTimeout(() => {
            setTotalArticle(count);
          }, 1000);
        }
      } else {
        setTotalArticle(result?.data?.data);
      }
    } catch (err) {
      console.log("-err", err);
    }
  };

  const getCountryLocation = async () => {
    if (localStorage.getItem("i18nextLng")) {
      i18n.changeLanguage(localStorage.getItem("i18nextLng"));
      return;
    } else {
      let ip;
      let userLang = navigator.language || navigator.userLanguage;
      if (userLang == "ru") {
        await axios
          .get(`https://ipinfo.io?token=e06cd7088ea376`)
          .then((res) => {
            ip = res.data.ip;
            // console.log(res);
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        //  axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;

        await axios
          .get(`http://www.geoplugin.net/json.gp?ip=${ip}&position=true`)
          .then((res) => {
            console.log(res);
            if (
              res.data.geoplugin_countryName == "Russia" ||
              res.data.geoplugin_countryName == "Azerbaijan" ||
              res.data.geoplugin_countryName == "Belarus" ||
              res.data.geoplugin_countryName == "Ukraine" ||
              res.data.geoplugin_countryName == "Georgia"
            ) {
              i18n.changeLanguage("ru");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        i18n.changeLanguage("en");
      }
    }

    // console.log(userLang);
    //console.log(i18.language);
  };

  window.addEventListener("scroll", (event) => {
    // console.log(event);
    // let scrollTop = event.currentTarget.scrollTop;
    // console.log(scrollTop);
    if (window.scrollY > 60) {
      // console.log(window.scrollY);
      setAddScroll(true);
    } else {
      setAddScroll(false);
    }
  });

  // useEffect(() => {
  //   localStorage.setItem("token-id", "d9180724-afa6-4226-983d-232b25a49dd0");
  // }, []);

  return (
    <div className="App">
      {/* <Header />
      <Body />
      <Footer /> */}

      <header style={{ padding: "0" }}>
        <Navbar
          className={addScroll == true ? "sticky" : null}
          collapseOnSelect
          expand="lg"
          fixed="top"
        >
          <Container>
            <div className="d-flex justify-content-between navbar-outer">
              <Navbar.Brand href="/">
                <img src={path_image + "docintel-logo.svg"} alt="" />
              </Navbar.Brand>
              <Button
                variant="primary"
                className="login_btn"
                onClick={() => {
                  navigate("/Login");
                }}
              >
                Login
              </Button>
            </div>
          </Container>
        </Navbar>
      </header>

      <div className="banner">
        <Container>
          <Row>
            <div className="banner-inset">
              <div className="banner-left col-5 col-md-5">
                <h2>The definitive daily update of all clinical articles</h2>
                <h6>
                  Docintel helps clinicians stay informed and save time by
                  removing the need to manually search for relevant articles
                </h6>
                <Button
                  variant="primary"
                  className="signup"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Sign Up
                </Button>
              </div>
              <div className="banner-right col-7 col-md-7">
                <div className="banner-img">
                  <img src={path_image + "brain.svg"} alt="" />
                </div>
                <h3>
                  <span>Albert</span> the AI inside
                </h3>
                <h6>
                  To date Albert has sorted and related{" "}
                  <span>{TotalArticle}</span> articles
                </h6>
                <span className="banner-circle">
                  <img src={path_image + "banner-right-circle.png"} alt="" />
                </span>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="main-page-spacer">
        <div className="albert-app-download">
          <Container>
            <Row>
              <Col xs={6} md={6} className="albert-app-left">
                <h3>
                Download The App!
                </h3>
                <h6>
                Docintel is free in the App stores and online through any browser.
                </h6>
                <p>Albert will always be free to clinicians.</p>
              </Col>
              <Col xs={6} md={6} className="albert-app-right d-flex align-items-center justify-content-end">
                  {/* <div className="d-flex">
                    <img src={path_image + "mac-app-icon.svg"} alt="" />
                    <img src={path_image + "app-store-icon.svg"} alt="" />
                  </div> */}
                  <div className="app-download-btn d-flex">
                  <a
                    href="https://itunes.apple.com/app/docintel/id1459624369?ls=1&amp;mt=8"
                    target="_blank"
                  >
                    <img src={path_image + "apple-store.svg"} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.docintel"
                    target="_blank"
                  >
                    <img src={path_image + "google-play.svg"} alt="" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="albert-ai-review">
          <Container>
            <Row>
              <Col xs={7} md={7}>
                <div className="ai-review-img">
                  <img src={path_image + "ai-review.png"} alt="" />
                </div>
              </Col>
              <Col xs={5} md={5} className="ai-review-right">
                <h3>
                  Albert the AI reviews thousands of articles every day and
                  assigns a Clinical Value (CV) to each
                </h3>
                <h6>
                  Each speciality has their own unique Clinical Value scoring
                </h6>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="clinicial-sort">
          <Container>
            <Row>
              <div className="clinicical-sort-inset">
                <h3>
                  Clinicians can sort content by <span>40</span>{" "}
                  <span className="strong">specialties</span>
                </h3>
              </div>
            </Row>
          </Container>
        </div>
        <div className="library-store">
          <Container>
            <Row>
              <Col xs={5} md={5} className="ai-review-right">
                <h3>The library lets you store content on and offline</h3>
                <h6 className="syncs-across">
                  And it syncs across all your devices
                </h6>
                <h6>
                  You can sort and search by date and title. And delete any
                  content that's no longer needed
                </h6>
              </Col>
              <Col xs={7} md={7}>
                <div className="ai-review-img">
                  <img src={path_image + "library-img.png"} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="why_albert">
          <Container>
            <Row>
              <div className="why_albert-inset">
                <h2>
                  Why <strong>Albert</strong> is needed <span>?</span>
                </h2>
                <p>
                  The frontline of patient care is the clinicians who have to
                  apply knowledge, experience and training. In many countries
                  CPD/CME have become mandatory and demand extensive proof of
                  education and improvement. However, as a society we're at the
                  same time increasing the burden from work without increasing
                  time for continued training.
                </p>
                <p>
                  So, we built Albert, the first Artificial Intelligence tool
                  aimed at helping HCP's by serving up what is most likely to
                  have a clinical value on daily practice. Albert will learn
                  from you and your peers based on your choices of reading and,
                  we hope, get to be a tool you rely on.
                </p>
                <p>
                  We focus on primary resources now as we believe starting at
                  the source brings the best insight to a knowledged mind. Over
                  time we will seek other sources that can help equip you with
                  data and information to aid your decision-making skills.
                </p>
              </div>
            </Row>
          </Container>
        </div>
        <div className="albert-ai-review app-download">
          <Container>
            <Row>
              <Col xs={7} md={7}>
                <div className="ai-review-img">
                  <img src={path_image + "app-download.png"} alt="" />
                </div>
              </Col>
              <Col xs={5} md={5} className="ai-review-right">
                <h3>Download The App!</h3>
                <h6>
                  Docintel is free in the App stores and online through any
                  browser.
                </h6>
                <h6 className="albert-app-title">
                  Albert will always be free to clinicians.
                </h6>
                <div className="app-download-btn d-flex">
                  <a
                    href="https://itunes.apple.com/app/docintel/id1459624369?ls=1&amp;mt=8"
                    target="_blank"
                  >
                    <img src={path_image + "apple-store.svg"} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.docintel"
                    target="_blank"
                  >
                    <img src={path_image + "google-play.svg"} alt="" />
                  </a>
                </div>
                <Button
                  variant="primary"
                  className="signup"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  {t("Sign Up")}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="privacy-sec">
          <Container>
            <Row>
              <div className="privacy-text">
                <h2>Privacy</h2>
                <ul>
                  <li>
                    {" "}
                    We will NOT sell your personal details to 3rd parties!
                  </li>
                  <li>
                    You will ALWAYS have a choice of NOT accepting sponsored
                    content.
                  </li>
                  <li>You can ALWAYS change your setting.</li>
                  <li>
                    We will ALWAYS improve &#38; make changes, and some of those
                    changes will demand changes to the Privacy and Terms of Use
                    which we might not notify you about, but if anything changes
                    significantly in regards to your private data, or the way we
                    handle it, we will ALWAYS ask for your consent.
                  </li>
                </ul>
              </div>
            </Row>
          </Container>
        </div>
        <div className="be_clear">
          <Container>
            <Row>
              <div className="be_clear_inset">
                <h2>To be clear</h2>
                <ul>
                  <li>
                    We Albert is building a data set of usage and as a minimum
                    we gather data usage for Albert's advice to improve and
                    inform 3rd parties about aggregated and anonomised trends.
                    But we'd like to ask your permission to also let us use your
                    data to profile you and solicit educational content on your
                    behalf from potential sponsors.
                  </li>
                  <li>
                    We will not share your contact details with sponsors, but we
                    will let them know what you chose to engage with so they can
                    also become better at delivering content you find helps your
                    professional development. For a full read on what we do have
                    a look here at our Privacy and Terms of Use.
                  </li>
                </ul>
              </div>
            </Row>
          </Container>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
