import React, { useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loader } from "../loader";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import ErrorModel from "./ErrorModel";

import * as Yup from "yup";
import { postData } from "../axios/apiHelper";
import { ENDPOINT } from "../axios/apiConfig";
const ChangePassword = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [message, setMessage] = useState("");
  const [validator] = React.useState(new SimpleReactValidator());
  const [oldPassword, setOldPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [status, setStatus] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [activeOldPassword, setActiveOldPassword] = useState("");
  const [activeNewPassword, setActiveNewPassword] = useState("");
  const [activeConfirmPassword, setActiveConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    password: Yup.string()
      .required("Password is required")
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New password cannot be the same as old password"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, setError, formState } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    setActiveOldPassword(false);
  }, [errors]);

  const saveButtonClicked = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      // console.log("hi");
    } else {
      validator.showMessages();
      setRenderAfterValidation(renderAfterValidation + 1);
    }
  };

  async function onSubmit(data) {
    try {
      let body = {
        App_used: "docintel",
        Build_number: "1.0",
        Device_used: "Web",
        LangCode: i18n.language,
        device_id: "web",

        token: localStorage.getItem("token-id"),
        new_password: data.password,
        old_password: data.oldPassword,
      };
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show");
      const res = await postData(ENDPOINT.CHANGEPASSWORD, body);

      if (res.status == 200) {
        if (res?.data?.change_password_doc?.success) {
          setActiveOldPassword(false);

          setShow(true);
        } else if (!res.data.change_password_doc.success) {
          setError("oldPassword", {
            message: res.data.change_password_doc.status,
          });
        } else {
          setActiveOldPassword(true);
        }
        loader("hide");
      }
    } catch (err) {
      loader("hide");
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }
  }

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const showNewPasswordClicked = () => {
    setShowNewPassword(!showNewPassword);
  };

  const showConfirmPasswordClicked = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <>
        <div className="mobile-header" style={{ display: "none" }}>
          <div
            className="btn-back"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <button className="btn btn-primary btn-back">
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                  fill="#0066BE"
                ></path>
              </svg>
            </button>
          </div>
          <h6>{t("Change Password")}</h6>
        </div>
        <div className="wrapper">
          <div className="user-profile">
            <div className="container">
              <div className="row">
                <div className="user-detail-inset">
                  <h4>
                    <h4> {t("Change Password")}</h4>
                  </h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <label for="user_name">{t("Old Password")}</label>
                      <div>
                      <input
                        {...register("oldPassword")}
                        type={showPassword == true ? "text" : "password"}
                        // className={

                        //   // activeOldPassword == true
                        //   //   ? "form-control custom-error validationErrors"
                        //   //   : "form-control"
                        // }
                        className={
                          errors.oldPassword?.message
                            ? "validationErrors form-control"
                            : "form-control"
                        }
                        id="user_name"
                        name="oldPassword"
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                        value={oldPassword}
                      />
                      {console.log("-dfdf dfdf", errors?.oldPassword?.message)}
                      <div className="error">
                        {t(errors?.oldPassword?.message)}
                      </div>

                      <span>
                        <svg
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                            fill="#97B6CF"
                          />
                        </svg>
                      </span>
                      <span className="pawword_img">
                        <img
                          src={
                            showPassword == true
                              ? path_image + "password-protected.png"
                              : path_image + "Union.png"
                          }
                          onClick={showPasswordClicked}
                          alt=""
                        />
                      </span>
                      </div>
                    </div>
                    <div
                      className={
                        activeNewPassword == true
                          ? "active form-group"
                          : "form-group"
                      }
                    >
                      <label for="user_name">{t("New Password")}</label>
                      <div>
                      <input
                        {...register("password")}
                        type={showNewPassword == true ? "text" : "password"}
                        className={
                          errors.password?.message
                            ? "validationErrors form-control"
                            : "form-control"
                        }
                        id="user_name"
                        name="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                      />

                      <div className="error">{t(errors.password?.message)}</div>

                      <span>
                        <svg
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                            fill="#97B6CF"
                          />
                        </svg>
                      </span>
                      <span className="pawword_img">
                        <img
                          src={
                            showNewPassword == true
                              ? path_image + "password-protected.png"
                              : path_image + "Union.png"
                          }
                          onClick={showNewPasswordClicked}
                          alt=""
                        />
                      </span>
                      </div>
                    </div>
                    <div
                      className={
                        activeConfirmPassword == true
                          ? " form-group"
                          : "form-group"
                      }
                    >
                      <label for="user_name">{t("Confirm Password")}</label>
                      <div>
                      <input
                        {...register("confirmPassword")}
                        type={showConfirmPassword == true ? "text" : "password"}
                        id="user_name"
                        name="confirmPassword"
                        className={
                          errors.confirmPassword?.message
                            ? "validationErrors"
                            : "form-control"
                        }
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                      />

                      <div className="error">
                        {t(errors.confirmPassword?.message)}
                      </div>
                      <span>
                        <svg
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                            fill="#97B6CF"
                          />
                        </svg>
                      </span>
                      <span className="pawword_img">
                        <img
                          src={
                            showConfirmPassword == true
                              ? path_image + "password-protected.png"
                              : path_image + "Union.png"
                          }
                          onClick={showConfirmPasswordClicked}
                          alt=""
                        />
                      </span>
                      </div>
                    </div>

                    <button
                      //    onClick={(e) => saveButtonClicked(e)}
                      type="submit"
                      className="btn btn-primary change_password"
                    >
                      {t("Change Password")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + "like.svg"} alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p>{t("Password updated successfully!")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ChangePassword;
