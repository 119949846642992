import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Navigate, useNavigate } from "react-router";
const QuickTutorial2 = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const naviageLibrary = () => {
    navigate("/Albert");
  };
  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div class="tabs_menu_logo">
          <img src="images/albert_logo.svg" alt="" />
        </div>
      </div>
      <div
        className={
          i18n.language == "en"
            ? "space_extra new english"
            : i18n.language == "ru"
            ? "space_extra new russian"
            : i18n.language == "es"
            ? "space_extra new spanish"
            : "space_extra"
        }
      >
        <Container>
          <Row>
            <div className="setting_content_space">
              <div className="privacy setting_content">
                <div className="privacy-policy">
                  <div className="privacy-content privacy_setting_content tutorial-content tutorial2">
                    <div className="tutorial-block">
                      <p>
                        {t(
                          "To help teach Albert we hope you will alert us if something is spot on or wrong by using the thumbs-up and thumbs-down buttons."
                        )}
                      </p>
                      <p className="setting-consent-img3">
                        <img src={path_image + "like_dislike.png"} alt="" />
                      </p>
                      <p>{t("You can also sort by date and type of access")}</p>
                      <div className="setting-consent-img4">
                        <div>
                          <span>{t("Sort by")}</span>
                          <img src={path_image + "sorted_by.png"} alt="" />
                        </div>
                      </div>
                      <div className="space_line"></div>
                      <div className="article_access">
                        <label>{t("Article Access")}</label>
                        <ul>
                          <li className="all">{t("All Articles")}</li>
                          <li className="open">{t("Open Access")}</li>
                          <li className="limited">{t("Limited Access")}</li>
                          <li className="free">{t("Free access")}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutorial-box-footer d-flex justify-content-between">
                      <div className="tutorial-box-layout">
                        <Button
                          variant="primary"
                          onClick={() => {
                            navigate("/tutorial");
                          }}
                          className="consent_btn"
                        >
                          <img src={path_image + "prev-page.png"} alt="" />
                          {t("Back")}
                        </Button>
                      </div>
                      <div className="items-selection">
                        <span>&nbsp;</span>
                        <span className="active">&nbsp;</span>
                      </div>
                      <div className="tutorial-box-layout">
                        <Button
                          variant="primary"
                          onClick={naviageLibrary}
                          className="consent_btn direct"
                        >
                          {t("See Albert")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default QuickTutorial2;
