import React, { useState, useRef } from "react";
import axios from "axios";
import { loader } from "../loader";

import { Modal, Button } from "react-bootstrap";

import { useTranslation, Trans } from "react-i18next";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";

let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;

const Popups = (props) => {
  const { t, i18n } = useTranslation();
  const input1Ref = useRef(false);
  const input2Ref = useRef(false);

  const [confirmation, setConfirmation] = useState(false);
  const [data, setData] = useState("");
  const [confirmationImage, setConfirmationImage] = useState("");
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [dislikePopup, setShowDislikePopup] = useState(false);
  const [confirmationAlertImage, setConfirmationAlertImage] = useState("");
  const [message, setMessage] = useState("");
  const yesPleaseClicked = async (data) => {
    if (data == "library") {
      let url =ENDPOINT.DELETESAVEDLIBRARY
     
      let body = {
        token: localStorage.getItem("token-id"),
        id: props.articleId,
        LangCode: i18n.language,
      };
      if (props?.type) {
        console.log(props);
        console.log(localStorage.getItem("username"));
        delete body.token;
        body.type = props.type;
        body.email = localStorage.getItem("queryparamsemail");
        url =  ENDPOINT.DELETESAVEDLIBRARY_ANDROID
      }
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show");
      const res = await postData(url, body);

      if (res.status == 200) {
        props.handleClose();
        props.setSaved(
          props.saved.filter((item) => item.saved_id !== props.articleId)
        );
        props.setAddedToLibrary(false);
        setConfirmation(true);
        setConfirmationImage("library.svg");
        loader("hide");
      }

      // .catch((err) => {
      //   console.log(err);
      // });
    } else if (data == "minilibrary") {
      let body = {
        token: localStorage.getItem("token-id"),
        id: props.articleId,
        LangCode: i18n.language,
      };
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show");
      const res = await postData(ENDPOINT.DELETESAVEDLIBRARY, body);

      if (res.status == 200) {
        props.setData(
          props.data.filter((item) => item._id !== props.articleId)
        );
        props.setNcbData(
          props.ncbData.filter((item) => item._id !== props.articleId)
        );
        props.closeAbstractData();
        setConfirmation(true);
        setConfirmationImage("library.svg");
        props.handleClose();
        loader("hide");
      }

      // .catch((err) => {
      //   console.log(err);
      // });
    } else {
      let url = ENDPOINT.DELETEALERT
      let body = {
        user_id: localStorage.getItem("token-id"),
        link: props.articleId,
        LangCode: i18n.language,
      };
      if (props?.type) {
        delete body.user_id;
        body.type = props.type;
        body.email = localStorage.getItem("queryparamsemail");
        url = ENDPOINT.DELETEALERT_ANDROID
      }
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      loader("show");
      const res = await postData(url, body);

      if (res.status == 200) {
        props.handleClose("alert");
        props.setAlert(false);
        props.setgetAlert(
          props.getalert.filter((item) => item.article_id !== props.articleId)
        );
        setMessage(res.data.message);
        setConfirmationAlert(true);
        setConfirmationAlertImage("check.svg");
        loader("hide");
      }

      // .catch((err) => {
      //   console.log(err);
      // });
    }
  };

  const alertFunctioin = async () => {
    let url = ENDPOINT.ADDALERT
    let body = {
      user_id: localStorage.getItem("token-id"),
      link: props.articleId,
      LangCode: i18n.language,
    };
    if (props?.type) {
      delete body.user_id;
      body.type = props.type;
      body.email = localStorage.getItem("queryparamsemail");
      url = ENDPOINT.ADDALERT_ANDROID
    }

    axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
    loader("show");
    const res = await postData(url, body);

    if (res.status == 200) {
      let newGetAlertValue = props.getalert;
      props.handleClose("alert");
      newGetAlertValue.push({
        article_id: props.articleId,
      });
      props.setgetAlert(newGetAlertValue);
      props.setAlert(true);
      setConfirmationAlert(true);
      setConfirmationAlertImage("check.svg");
      setMessage(res.data?.message);
      loader("hide");
    }

    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const dislikeOkClicked = () => {
    setShowDislikePopup(true);
  };

  const handleClick = (e) => {
    var newData = data;
    if (e.target.checked) {
      if (newData) {
        if (e.target.name == "highCv") {
          input2Ref.current.checked = false;
          input1Ref.current.checked = true;
          let addData = newData.replace(
            new RegExp(
              `,${input2Ref.current.value}|${input2Ref.current.value}|${input2Ref.current.value},`
            ),
            ""
          );
          if (addData) {
            addData += "," + e.target.value;
            setData(addData);
          } else {
            setData(e.target.value);
          }
        } else if (e.target.name == "lowCv") {
          input2Ref.current.checked = true;
          input1Ref.current.checked = false;
          let addData = newData.replace(
            new RegExp(
              `,${input1Ref.current.value}|${input1Ref.current.value},|${input1Ref.current.value}`
            ),
            ""
          );
          if (addData) {
            addData += "," + e.target.value;
            setData(addData);
          } else {
            setData(e.target.value);
          }
        } else {
          newData += "," + e.target.value;
          setData(newData);
        }
      } else {
        setData(e.target.value);
      }
    } else {
      if (newData) {
        const removeData = newData.replace(
          new RegExp(`,${e.target.value}|${e.target.value}|${e.target.value}`),
          ""
        );
        setData(removeData);
      } else {
        setData(e.target.value);
      }
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              {props.image == "like.svg" ? (
                <img src={path_image + "like.svg"} alt="" />
              ) : (
                ""
              )}
              {props.image == "thanks-img.svg" ? (
                <img
                  src={path_image + "thanks-img.svg"}
                  style={{
                    display: props.image == "thanks-img.svg" ? "show" : "none",
                  }}
                  alt=""
                />
              ) : (
                ""
              )}
              {props.image == "dislike.svg" ? (
                <img src={path_image + "dislike.svg"} alt="" />
              ) : (
                ""
              )}
              {props.image == "alert.svg" ? (
                <img src={path_image + "alert.svg"} alt="" />
              ) : (
                ""
              )}
              {props.image == "library.svg" ? (
                <img src={path_image + "library.svg"} alt="" />
              ) : (
                ""
              )}
              <img
                src={path_image + "stop-alert.svg"}
                style={{
                  display: props.image == "stop-alert.svg" ? "show" : "none",
                }}
                alt=""
              />
              <img
                src={path_image + "create-alert.svg"}
                style={{
                  display: props.image == "create-alert.svg" ? "show" : "none",
                }}
                alt=""
              />
            </div>
            {props?.heading ? <h6>{t(props?.heading)}</h6> : ""}
            {props.message == "Thank you for your valuable feedback" ? (
              <>
                <p>{t(props.message)}</p>
              </>
            ) : (
              <p>{t(props.message)}</p>
            )}
            {props.message ==
            "Please add your feedback to improve the content:" ? (
              <div className="feedback-content">
                <div className="form-group user_type">
                  <input
                    onClick={handleClick}
                    className="check_type"
                    type="checkbox"
                    id="Wrong Speciality"
                    name="user_type"
                    value="Wrong Speciality"
                  />
                  <label for="Wrong Speciality">{t("Wrong Speciality")}</label>
                </div>
                <div className="form-group user_type">
                  <input
                    onClick={handleClick}
                    className="check_type"
                    type="checkbox"
                    id="low_cv"
                    ref={input2Ref}
                    name="lowCv"
                    value="Too low CV"
                  />
                  <label for="low_cv">{t("Too low CV")}</label>
                </div>
                <div className="form-group user_type">
                  <input
                    onClick={handleClick}
                    className="check_type"
                    type="checkbox"
                    ref={input1Ref}
                    id="high_cv"
                    name="highCv"
                    value="Too high CV"
                  />
                  <label for="high_cv">{t("Too high CV")}</label>
                </div>
                <div className="form-group user_type">
                  <input
                    onClick={handleClick}
                    className="check_type"
                    type="checkbox"
                    id="Other"
                    name="user_type"
                    value="Other"
                  />
                  <label for="Other">{t("Other")}</label>
                </div>
              </div>
            ) : null}
            {props.message ==
            "The article added to your library successfully" ? (
              <span>{t("*Please refresh the library page to see it")}</span>
            ) : (
              ""
            )}
          </Modal.Body>

          <Modal.Footer>
            {props.message ==
            "Are you sure you want to remove the content from the library?" ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => yesPleaseClicked("library")}
                >
                  {t("Yes Please!")}
                </Button>
                <Button
                  className="cancel-button"
                  variant="secondary"
                  onClick={props.handleClose}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : props.message ==
              "Are you sure you want to remove the content from the library?." ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => yesPleaseClicked("minilibrary")}
                >
                  {t("Yes Please!")}
                </Button>
                <Button
                  className="cancel-button"
                  variant="secondary"
                  onClick={props.handleClose}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : props.message ==
              "I will create an alert in your Library for when the full article becomes available" ? (
              <>
                <Button variant="secondary" onClick={() => alertFunctioin()}>
                  {t("Yes Please!")}
                </Button>
                <Button
                  className="cancel-button"
                  variant="secondary"
                  onClick={props.handleClose}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : props.message ==
              "Do you want me to remove the alert and abstract from your Library?" ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => yesPleaseClicked("alert")}
                >
                  {t("Yes Please!")}
                </Button>
                <Button
                  className="cancel-button"
                  variant="secondary"
                  onClick={props.handleClose}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : props.message ==
              "Please add your feedback to improve the content:" ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    props.handleDislikeSubmit(data);
                    setData("");
                  }}
                >
                  {t("Ok")}
                </Button>
              </>
            ) : (
              <Button
                variant="secondary"
                onClick={() => {
                  props.handleClose();
                  // props.handleDislikeSubmit(data);
                }}
              >
                {t("Ok")}
              </Button>
            )}
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={confirmation}
        onHide={() => {
          setConfirmation(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src={path_image + confirmationImage} alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p> {t("The article removed from your library successfully")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setConfirmation(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={confirmationAlert}
        onHide={() => {
          setConfirmationAlert(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {confirmationAlertImage ? (
              <div className="thanks_register_img">
                <img src={path_image + confirmationAlertImage} alt="" />
              </div>
            ) : (
              ""
            )}
            <h6> {t("Done")}</h6>
            <p>
              {message
                ? t(message)
                : t("Alert for the article removed successfully.")}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setConfirmationAlert(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default Popups;
