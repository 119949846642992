import React from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import { UserAgent } from "react-useragent";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import queryString from "query-string";
import { Link } from "react-router-dom";

const DocintelPrivacy = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    if (queryParams.LangCode == "ru") {
      i18n.changeLanguage("ru");
    } else if (!queryParams.LangCode) {
      i18n.changeLanguage(i18n.language);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const location = useLocation();

  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;

  return (
    <>
      <UserAgent
        render={({ ua }) => {
          return (
            <>
              {" "}
              {ua.md.ua.includes("App-API") ? null : (
                <header className="default-page">
                  <div className="main_n">
                    <div className="row">
                      <div className="col-md-3 col-sm-3" id="left_menu">
                        {/* <div className="btn-back">
                <button
                  className="btn btn-primary btn-back"
                  onClick={() => {
                    if (location.state.path == "App") {
                      navigate("/");
                    } else if (location.state.path == "PrivacySettings2") {
                      navigate("/privacy-setting");
                    } else {
                      navigate("/Library");
                    }
                  }}
                >
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.64983 17.5079C10.1167 17.041 10.1167 16.284 9.64983 15.8171L2.8866 9.05388L9.64952 2.29096C10.1164 1.82407 10.1164 1.06707 9.64952 0.600174C9.18262 0.133276 8.42563 0.133275 7.95873 0.600174L0.350174 8.20873C-0.116725 8.67563 -0.116725 9.43262 0.350174 9.89952C0.357061 9.90641 0.364012 9.91319 0.371022 9.91988L7.95904 17.5079C8.42593 17.9748 9.18293 17.9748 9.64983 17.5079Z"
                      fill="#0066BE"
                    ></path>
                  </svg>
                </button>
              </div> */}
                      </div>
                      <div className="tabs_menu col-md-6 col-sm-6">
                        <div className="site-main-log1">
                          {/* <h4>{t("Privacy")}</h4> */}
                        </div>
                      </div>
                      <div id="right_menu" className="col-md-3 col-sm-3"></div>
                    </div>
                  </div>
                </header>
              )}{" "}
              <div class="wrapper-site">
                <div class="container">
                  <div class="row">
                    <div class="privacy  default-view">
                      <div class="privacy-policy">
                        {/* <h4>Privacy</h4> */}
                        <div className="site-logo">
                          <a href="https://albert.docintel.app/">
                            <img
                              style={{ cursor: "pointer" }}
                              src="https://albert.docintel.app/images/docintel-app-logo.svg"
                              alt="Docintel Logo"
                            />
                          </a>
                        </div>
                        <div className="privacy-content">
                          <p>
                            Docintel.app is committed to protecting your
                            personal information.
                          </p>

                          <p>
                            This Policy explains what personal information
                            Docintel.app Ltd (“Docintel.app”, “we”, “us”, “our”)
                            collects from you through our platform, how we use
                            the information and how we keep it secure.
                          </p>

                          <p>
                            This statement applies to our website and apps.
                            <br />
                            This policy applies as between you, the user of this
                            website, and Docintel.app the owner and provider of
                            this website. Our Privacy Policy does not extend to
                            any websites that can be accessed from our website
                            including, but not limited to, any links we may
                            provide on social media websites.
                          </p>
                          <ol start="1">
                            <li>Protection Law</li>
                          </ol>
                          <p>
                            Docintel.app is committed to complying with the data
                            protection law. We adhere to the law by ensuring
                            that the data you provide us will be:
                          </p>
                          <ul>
                            <li>
                              Processed lawfully, fairly and in a transparent
                              manner.
                            </li>
                            <li>
                              Collected for specific, explicit and legitimate
                              purposes and not further processed in a manner
                              that is incompatible with those purposes
                            </li>
                            <li>
                              Adequate, relevant and limited to what is
                              necessary for those purposes
                            </li>
                            <li>
                              Accurate and, where necessary, kept up to date
                            </li>
                            <li>
                              Kept in a form which permits identification of
                              data subject for no longer than is necessary for
                              the purposes for which the personal data is
                              processed
                            </li>
                            <li>
                              Processed in a manner that ensures appropriate
                              security of the personal data.
                            </li>
                          </ul>
                          <ol start="2">
                            <li>Collecting Personal Information</li>
                          </ol>
                          <p>
                            Docintel.app acts as the processor of the
                            information you provide.
                          </p>
                          <p>
                            We process the information for legitimate business
                            purposes and to help our clients provide our users
                            with tailored content aimed at improving their
                            professional development and knowledge.
                          </p>
                          <p>
                            If you decide not to provide us with your personal
                            data you may not be able to use some of our platform
                            and/or services.
                          </p>
                          <ol start="3">
                            <li>Data Collected</li>
                          </ol>
                          <p>
                            The following data may be collected by Docintel.app:
                          </p>
                          <ol className="lowar-alpha">
                            <li>name</li>
                            <li>date of birth</li>
                            <li>gender</li>
                            <li>job title</li>
                            <li>profession</li>
                            <li>
                              contact information such as email addresses and/or
                              telephone numbers
                            </li>
                            <li>
                              demographic information such as post code,
                              preferences and interests
                            </li>
                            <li>IP address (automatically collected)</li>
                            <li>
                              web browser type and version (automatically
                              collected)
                            </li>
                            <li>operating system (automatically collected)</li>
                            <li>
                              a list of URLs starting with a referring site,
                              your activity on this Website, and the site you
                              exit to (automatically collected)
                            </li>
                          </ol>
                          <ol start="4">
                            <li>We Use Data</li>
                          </ol>
                          <p>
                            Docintel.app uses your personal information for the
                            following reasons:
                          </p>
                          <ul>
                            <li>
                              To operate effectively as a business and to
                              perform essential business operations, including
                              providing products optimised for medical
                              professionals
                            </li>
                          </ul>
                          <p>
                            We are motivated to provide products which offer
                            outstanding resources for medical professionals,
                            including sponsored content. To enhance your
                            enjoyment and productivity on our platform, we
                            endeavour to identify and improve our services. To
                            ensure your experience with our products is
                            seamless, we continuously re-examine and iteratively
                            optimise user journeys on our platform. We infer
                            your location from your device IP address in order
                            to geo restrict certain content on our platform and
                            ensure smooth access for you without the need to
                            re-login when avoidable, and aid content selection
                            for sponsors of content. Product issues, identified
                            by users and communicated through customer support,
                            are effectively diagnosed and resolved using data
                            collected from interactions on the platform.
                            Decisions on product development and evaluations of
                            product performance are based on aggregate analysis
                            and business intelligence based on non-personal
                            data.
                          </p>
                          <p>
                            All our clients and partners are required to take
                            appropriate security measures to protect your
                            personal data in line with national legislation and
                            policies of the countries they reside in. No matter
                            which country our clients reside in Docintel.app
                            will always treat personal data as a minimum with a
                            level corresponding to the General Data Protection
                            Regulation. This means that you will have the rights
                            as set out in clause 5 (below) and have the right to
                            disclosure, erasure etc. from Docintel.app’s
                            database.
                          </p>
                          <p>
                            Should you wish to exercise your right to be
                            forgotten we will erase all data about you in both
                            platforms and request the data controller to do the
                            same. However, where consent was given we keep a
                            record of this for disclosures under legal
                            requirements, but we will delete all other data
                            collected.
                          </p>
                          <p>
                            In addition to the specific disclosure of personal
                            data set out in this section, we may disclose your
                            personal data where such disclosure is necessary for
                            compliance with a legal obligation to which we are
                            subject, or in order to protect your vital interests
                            or the vital interests of another natural person. We
                            may also disclose your personal data where such
                            disclosure is necessary for the establishment,
                            exercise or defence of legal claims, whether in
                            court proceedings or in an administrative or
                            out-of-court procedure.
                            <ul>
                              <li>
                                To deliver communications of personal interest
                                including product and content releases,
                                motivational prompts and in response to product
                                queries or support requests.
                              </li>
                            </ul>
                          </p>
                          <p>
                            <strong>Direct communications</strong>
                            Communications sent by Docintel.app come in the form
                            of emails to the email address provided by you
                            during the registration process and through
                            notifications delivered to your device. Docintel.app
                            may send you communications relating to new and
                            existing product and content releases and updates.
                            We send such communications so that you are aware of
                            changes we are making to the content or features of
                            our products, or new releases, which could affect
                            the usefulness of our core services to you. You, of
                            course, have the right to opt out of such email
                            communication at any time by using the unsubscribe
                            link, found at the bottom of every email.
                          </p>
                          <p>
                            <strong>Third party communications</strong>
                            Our clients contact you in various ways and deliver
                            content hosted by Docintel.app. They will do this
                            under their own set of regulations depending on your
                            relationship with them, which will be independent
                            from Docintel.app. We may also from time to time
                            push free sponsored content from our clients into
                            your account. You can always delete content received
                            in your account.
                            <ul>
                              <li>
                                To inform commercial partners and clients of
                                engagement and interactions on sponsored content
                                hosted on our platform
                              </li>
                            </ul>
                          </p>
                          <p>
                            When you receive content hosted by Docintel.app and
                            sponsored by our clients, such as medical device
                            companies and pharmaceutical companies, they are the
                            data controllers. As data controllers they will have
                            control over your private data which we will host in
                            our inforMed.pro platform and what is done with the
                            data is their decision.
                          </p>
                          <p>
                            The data controllers will have access to see your
                            name, email, IP address, what you read and when, but
                            they will never see your password. Each data
                            controller will only see the data that is in
                            relation to what each of them have sponsored. Only
                            Docintel.app and you can see all the content you
                            have engaged with. You can find it in the reading
                            list under your CPD Log in the apps.
                            <br />
                            Should you contact us about your right to
                            disclosure, erasure etc. we will delete what we can
                            from our database and inform each data controller
                            about your desire to be forgotten. We will inform
                            you who has received any personal data about you so
                            that you may contact them for further erasure.
                          </p>
                          <p>
                            Our aim with processing your private data is to help
                            our clients to identify better content that is more
                            suited to help you in your professional capacity.
                          </p>
                          <p>
                            To the extent that the legal basis for our
                            processing of your personal information is consent,
                            you have the right to withdraw that consent at any
                            time by emailing dpo@Docintel.app.com. Withdrawal
                            will not affect the lawfulness of processing before
                            the withdrawal.
                          </p>
                          <ol start="5">
                            <li>Accessing your personal data</li>
                          </ol>
                          <p>Under the data protection legislation you have:</p>
                          <ul>
                            <li>the right to be informed</li>
                            <li>the right to access</li>
                            <li>the right to rectification</li>
                            <li>the right to erasure</li>
                            <li>the right to restrict processing</li>
                            <li>the right to object and</li>
                            <li>
                              rights in relation to automated decision making
                              and profiling
                            </li>
                          </ul>
                          <p>
                            To learn more about your rights you should consult
                            the data protection legislation and the country
                            guidance from the relevant supervisory authority.
                          </p>
                          <p>
                            Upon written request to our data protection officer
                            we will provide you with information about what
                            personal data we hold about you. To be able to
                            process your request we may ask you to verify your
                            identity or ask more information about your request.
                            Where we are legally permitted to do so, we may
                            decline your request but we will explain why if we
                            do so.
                            <br />
                            You have the right to lodge a complaint with a
                            supervisory authority if you think that our
                            processing of your personal data infringes data
                            protection laws.
                          </p>
                          <ol start="6">
                            <li>Data Retention</li>
                          </ol>
                          <p>
                            Docintel.app will retain personal data for as long
                            as necessary to fulfil our aim of improving content
                            provided to you.
                          </p>
                          <ol start="7">
                            <li>Securing Your Information</li>
                          </ol>
                          <p>
                            Data security is of great importance to Docintel.app
                            and to protect your data we have put in place
                            suitable physical, electronic and managerial
                            procedures to safeguard and secure data collected
                            via our website and our apps.
                          </p>
                          <p>
                            Our main office is located in England, UK. We also
                            have affiliate offices situated in the EU and in
                            India. We are hosting all content and personal data
                            on servers within the EU. Our clients are based all
                            over the world. As such we may transfer data across
                            the globe, but will always abide by English data
                            protection legislation and as a minimum TheGeneral
                            Data Protection Regulation.
                          </p>
                          <ol start="8">
                            <li>Third Party Websites and Services</li>
                          </ol>
                          <p>
                            Docintel.app may, from time to time, employ the
                            services of other parties for dealing with matters
                            that may include, but are not limited to, delivery
                            of sponsored items, search engine facilities,
                            advertising and marketing. The providers of such
                            services may have access to certain personal data
                            provided by users of this website.
                          </p>
                          <p>
                            Any data used by such parties is used only to the
                            extent required by them to perform the services that
                            Docintel.app requests. Any use for other purposes is
                            strictly prohibited. Furthermore, any data that is
                            processed by third parties shall be processed within
                            the terms of this Policy and in accordance with the
                            data protection legislation.
                          </p>
                          <ol start="9">
                            <li>Links to Other Websites</li>
                          </ol>
                          <p>
                            This website may, from time to time, provide links
                            to other websites. Docintel.app has no control over
                            such websites and is in no way responsible for the
                            content thereof. This Policy does not extend to your
                            use of such websites. Users are advised to read the
                            privacy policy or statement of other websites prior
                            to using them.
                          </p>
                          <ol start="10">
                            <li>Changes of Business Ownership and Control</li>
                          </ol>
                          <p>
                            Docintel.app may, from time to time, expand or
                            reduce our business and this may involve the sale
                            and/or the transfer of control of all or part of
                            Docintel.app. Data provided by users will, where it
                            is relevant to any part of our business so
                            transferred, be transferred along with that part and
                            the new owner or newly controlling party will, under
                            the terms of this Policy, be permitted to use the
                            data for the purposes for which it was originally
                            supplied to us.
                          </p>
                          <p>
                            In the event that any data submitted by users is to
                            be transferred in such a manner, you will not be
                            contacted in advance and informed of the changes.
                          </p>
                          <ol start="11">
                            <li>Cookies</li>
                          </ol>
                          <p>
                            This website may place and access certain cookies on
                            your computer. Docintel.app uses cookies to improve
                            your experience of using the website and to improve
                            our range of products and services. Docintel.app has
                            carefully chosen these cookies and has taken steps
                            to ensure that your privacy is protected and
                            respected at all times.
                          </p>
                          <p>
                            All cookies used by this website are used in
                            accordance with current English and EU cookie law.
                          </p>
                          <p>
                            Before the website places any cookies on your
                            computer, you will be presented with a message bar
                            requesting your consent to set those cookies. By
                            giving your consent to the placing of cookies you
                            are enabling Docintel.app to provide the best
                            possible experience and service to you. You may, if
                            you wish, deny consent to the placing of cookies;
                            however certain features of the website may not
                            function fully or as intended.
                          </p>
                          <p>
                            This website uses analytics services provided by
                            Docintel.app. Website analytics refers to a set of
                            tools used to collect and analyse usage statistics,
                            enabling us to better understand how users use the
                            website. This, in turn, enables us to improve the
                            website and the products and services offered
                            through it. You do not have to allow us to use these
                            cookies, as detailed below, however, whilst our use
                            of them does not pose any risk to your privacy or
                            your safe use of the Website it does enable us to
                            continually improve our business. Some services are
                            only available via registration and if you choose
                            not to register we may not be able to let you access
                            some content. The reason for this can both be
                            commercial interests from sponsors of content or in
                            order to comply with local regulations such as The
                            Physician PaymentsSunshine Act in the USA.
                          </p>
                          <p>
                            You can choose to enable or disable cookies in your
                            internet browser. Most internet browsers also enable
                            you to choose whether you wish to disable all
                            cookies or only third party cookies. By default,
                            most internet browsers accept cookies but this can
                            be changed. For further details, please consult the
                            help menu in your internet browser.
                          </p>
                          <p>
                            You can choose to delete cookies at any time however
                            you may lose any information that enables you to
                            access the website more quickly and efficiently
                            including, but not limited to, personalisation
                            settings.
                          </p>
                          <p>
                            It is recommended that you ensure that your internet
                            browser is up-to-date and that you consult the help
                            and guidance provided by the developer of your
                            internet browser if you are unsure about adjusting
                            your privacy settings.
                          </p>
                          <ol start="12">
                            <li>Changes to this Policy</li>
                          </ol>
                          <p>
                            Docintel.app reserves the right to revise this
                            Policy as we may deem necessary from time to time or
                            as may be required by law. Any changes will be
                            immediately posted on the website and you are deemed
                            to have accepted the terms of the Policy on your
                            first use of the website following the alterations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
};
export default DocintelPrivacy;
