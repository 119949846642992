import Modal from "react-bootstrap/Modal";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Content from "./Content";

import { useNavigate } from "react-router-dom";
import { loader } from "../loader";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import queryString from "query-string";
import { toast } from "react-toastify";
const Setpassword = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [active, setActive] = useState(false);
  const [apiHit, setApiHit] = useState(false);
  const [failStatus, setFailStatus] = useState(false);
  const queryParams = queryString.parse(window.location.search);
  console.log(queryParams);

  console.log(queryParams);

  useEffect(() => {
    if (queryParams.lang == "ru" || queryParams.LangCode == "ru") {
      console.log("ru---");
      i18n.changeLanguage("ru");
    } else if (queryParams.lang == "es" || queryParams.LangCode == "es") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const [renderAfterValidation, setRenderAfterValidation] = useState(0);
  const [validator] = React.useState(
    new SimpleReactValidator({
      messages: {
        in: t("Passwords need to match!"),
      },
    })
  );
  const [rememberMe, setRememberMe] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    activeLink();
  }, []);

  const activeLink = async () => {
    let body = {
      token: queryParams.hthhsdfhsfh.substring(
        0,
        queryParams.hthhsdfhsfh.length - 4
      ),
      email: queryParams.email,
      LangCode: i18n.language,
    };
    loader("show");
    axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
    await axios
      .post(`check-password-link`, body)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log(res);
          setActive(res.data.success);
          setApiHit(true);
          loader("hide");
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const loginClicked = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const passwordChanged = (e) => {
    //   console.log(e.target.value);
    setPassword(e.target.value);
  };

  const confirmPasswordChanged = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      let body = {
        email: queryParams.email,
        token: queryParams.hthhsdfhsfh.substring(
          0,
          queryParams.hthhsdfhsfh.length - 4
        ),
        password: password,
        // LangCode: i18n.language,
      };
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      await axios
        .post(`/set-password`, body)
        .then((res) => {
          if (res.status == 200) {
            setShowSuccess(true);
            setFailStatus(false);
          }
        })
        .catch((err) => {
          setFailStatus(true);
          console.log(err);
        });
    } else {
      validator.showMessages();
      setRenderAfterValidation(renderAfterValidation + 1);
    }
  };

  const showConfirmPasswordClicked = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const showPasswordClicked = () => {
    setShowPassword(!showPassword);
  };
  const handleValidation = (data) => {
    return (
      <div className="srv-validation-message">
        {" "}
        {t(`${data?.props?.children}`)}
      </div>
    );
  };

  return (
    <>
      {active == true ? (
        <div className="page-background">
          <div className="login-sec">
            <h6>{t("Welcome To")}</h6>
            <h1>Docintel</h1>
            <p className="forgot-title">{t("Please set your new password")}</p>
            <Form className="set-password" onSubmit={(e) => handleLogin(e)}>
              <Form.Group className="mb-3 pass" controlId="formBasicEmail">
                <Form.Control
                  type={showPassword == true ? "text" : "password"}
                  value={password}
                  placeholder={t("Password")}
                  onChange={(e) => passwordChanged(e)}
                />
                {validator.message("password", password, "required")
                  ? handleValidation(
                      validator.message("password", password, "required")
                    )
                  : ""}
                <span>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                      fill="#97B6CF"
                    />
                  </svg>
                </span>
                <span className="pawword_img">
                  <img
                    src={
                      showPassword == true
                        ? "../" +path_image + "password-protected.png"
                        : "../" + path_image + "Union.png"
                    }
                    alt=""
                    onClick={showPasswordClicked}
                  />
                </span>
              </Form.Group>

              <Form.Group className="mb-3 pass" controlId="formBasicPassword">
                <Form.Control
                  type={showConfirmPassword == true ? "text" : "password"}
                  value={confirmPassword}
                  placeholder={t("Confirm Password")}
                  onChange={(e) => confirmPasswordChanged(e)}
                />
                {validator.message(
                  "confirmPassword",
                  confirmPassword,
                  `required|in:${password}`
                )
                  ? handleValidation(
                      validator.message(
                        "confirmPassword",
                        confirmPassword,
                        `required|in:${password}`
                      )
                    )
                  : ""}
                <span>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.625 7.5H13V5C13 2.2425 10.7575 0 7.99999 0C5.2425 0 3 2.2425 3 5V7.5H2.375C1.34167 7.5 0.5 8.34083 0.5 9.37499V18.125C0.5 19.1592 1.34167 20 2.375 20H13.625C14.6583 20 15.5 19.1592 15.5 18.125V9.37499C15.5 8.34083 14.6583 7.5 13.625 7.5ZM4.66666 5C4.66666 3.16166 6.16166 1.66667 7.99999 1.66667C9.83833 1.66667 11.3333 3.16166 11.3333 5V7.5H4.66666V5ZM8.83333 13.935V15.8333C8.83333 16.2933 8.46083 16.6667 7.99999 16.6667C7.53916 16.6667 7.16666 16.2933 7.16666 15.8333V13.935C6.67083 13.6458 6.33333 13.1142 6.33333 12.5C6.33333 11.5808 7.08083 10.8333 7.99999 10.8333C8.91916 10.8333 9.66666 11.5808 9.66666 12.5C9.66666 13.1142 9.32916 13.6458 8.83333 13.935Z"
                      fill="#97B6CF"
                    />
                  </svg>
                </span>
                <span className="pawword_img">
                  <img
                    src={
                      showConfirmPassword == true
                        ?  "../" + path_image + "password-protected.png"
                        : "../" + path_image + "Union.png"
                    }
                    alt=""
                    onClick={showConfirmPasswordClicked}
                  />
                </span>
              </Form.Group>
              <Button variant="primary" type="submit">
                {t("Save")}
              </Button>
            </Form>
          </div>
        </div>
      ) : apiHit == true ? (
        <div className="link-expire">
          <h3>{t("The link is expired")}</h3>
        </div>
      ) : null}

      <Modal
        show={showSuccess}
        onHide={() => {
          setShowSuccess(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src="../../../images/like.svg" alt="" />
            </div>
            <h6>{t("Done")}</h6>
            <p>
              {t("Your password is now set.")} <br />
              {t(
                "Your password can be used to log into the app and online in any browser."
              )}{" "}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              href="https://docintel.page.link/Zmya"
              onClick={() => {
                setShowSuccess(false);
              }}
            >
              {t("Go to the app")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal
        show={failStatus}
        onHide={() => {
          setFailStatus(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="thanks_like"
      >
        <div className="thanks_register_inner">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="thanks_register_img">
              <img src="../../../images/alert.svg" alt="" />
            </div>
            <h6>{t("Something went wrong.")}</h6>
            <p>{t("Could not change the password.")}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setFailStatus(false);
              }}
            >
              {t("OK")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default Setpassword;
