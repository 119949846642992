import React from "react";
import { Container, Row, Modal, Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Navigate, useNavigate } from "react-router";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ENDPOINT } from "../axios/apiConfig";
import { postData } from "../axios/apiHelper";
import { loader } from "../loader";
import axios from "axios";
import ErrorModel from "./ErrorModel";

const PrivacySettings2 = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const [message, setMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const defaultselection = location.state.defaultselection;
  const defaultselection2 = location.state.defaultselection2;
  const formBasicCheckbox = location.state.formBasicCheckbox;

  const navigate = useNavigate();
  const [contentALbert, setContentAlbert] = useState(0);
  const [activePopup, setActivePopup] = useState(false);
  const [confirmationImage, setConfirmationImage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("Oops");
  const [confirmationText, setConfirmationText] = useState(
    "Please tell us why you use Albert so he can learn the right things from the right people."
  );
  const albertContentDataChanged = (e) => {
    if (e.target.checked == true) {
      setContentAlbert(2);
    } else {
      setContentAlbert(0);
    }
  };

  const consentClicked = async (e) => {
    e.preventDefault();
    try {
      let body = {
        user_type: formBasicCheckbox,
        lib_content_option:
          defaultselection2 === true && defaultselection === true ? 2 : 0,
        //   token: localStorage.getItem("token-id"),
        LangCode: i18n.language,
      };
      axios.defaults.baseURL = process.env.REACT_APP_API_LOCAL_KEY;
      const res = await postData(ENDPOINT.USERUPDATEPROFILE, body);

      let userDetail = JSON.parse(localStorage.getItem("user_detail"));
      userDetail.content_option = 2;
      localStorage.setItem("user_detail", JSON.stringify(userDetail));
      navigate("/tutorial");
    } catch (err) {
      loader("hide");
      setMessage(err?.response?.data?.message);
      setErrorShow(true);
    }

    // navigate("/tutorial")
  };
  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div class="tabs_menu_logo">
          <img src="images/albert_logo.svg" alt="" />
        </div>
      </div>
      <div className="space_extra">
        <Container>
          <Row>
            <div className="setting_content_space">
              <div className="privacy setting_content">
                <div className="privacy-policy">
                  <div className="privacy-content privacy_setting_content setting_2">
                    <p>
                      {t(
                        "We will not share your contact details with sponsors, but we will let them know what you chose to engage with so they can also become better at delivering content you find helps your professional development. For a full read on what we do have a look here at our Privacy and Terms."
                      )}
                    </p>
                    <p>
                      <strong>{t("Just to be clear:")}</strong>
                    </p>
                    <ul className="privacy_setting_ul">
                      <li>
                        {t(
                          "We will NOT sell your personal details to 3rd party."
                        )}
                      </li>
                      <li>
                        {t(
                          "You will ALWAYS have a choice of NOT accepting sponsored content."
                        )}
                      </li>
                      <li>{t("You can ALWAYS change your setting.")}</li>
                      <li>
                        {t(
                          "We will ALWAYS improve & make changes, and some of those changes will demand changes to the Privacy and Terms of Use which we might not notify you about, but if anything changes significantly in regards to your private data, or the way we handle it, we will ask for your approval again."
                        )}
                      </li>
                      <li>
                        {t(
                          "And yes, obviously accepting and reading content helps us as a company and help us keep a roof over our heads."
                        )}
                      </li>
                    </ul>
                    <div className="privacy_link">
                      <p>
                        <strong>{t("Privacy policy")}</strong>{" "}
                        <a
                          href="/docintel-privacy"
                          target="_blank"
                          // style={{ cursor: "pointer" }}
                          // onClick={() => {
                          //   navigate("/docintel-privacy", {
                          //     state: {
                          //       path: "PrivacySettings2",
                          //     },
                          //   });
                          // }}
                        >
                          {t("click here")}.
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* <Button
                  variant="primary"
                  type="submit"
                  className="consent_btn"
                  onClick={consentClicked}
                >
                  I Consent
                </Button>

                <Button
                  variant="primary"
                  className="consent_btn"
                  onClick={() => {
                    navigate("/privacy-setting");
                  }}
                >
                  Back
                </Button> */}

                  <div className="tutorial-box-footer d-flex justify-content-between">
                    <div className="tutorial-box-layout">
                      <Button
                        variant="primary"
                        onClick={() => {
                          // navigate("/privacy-setting");
                          navigate("/privacy-setting", {
                            state: {
                              defaultselection2: defaultselection2,
                              defaultselection: defaultselection,
                              formBasicCheckbox: formBasicCheckbox,
                            },
                          });
                        }}
                        className="consent_btn"
                      >
                        <img src={path_image + "prev-page.png"} alt="" />
                        {t("Back")}
                      </Button>
                    </div>
                    <div className="items-selection">
                      <span>&nbsp;</span>
                      <span className="active">&nbsp;</span>
                    </div>
                    <div className="tutorial-box-layout">
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={consentClicked}
                        className="consent_btn direct"
                      >
                        {t("I Consent")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>

        {activePopup === true ? (
          <Modal
            show={activePopup}
            onHide={() => {
              setActivePopup(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="thanks_like"
          >
            <div className="thanks_register_inner">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="thanks_register_img">
                  <img src={path_image + "alert.svg"} alt="" />
                </div>
                <h6>{t(confirmationMessage)}</h6>
                <p>{t(confirmationText)}</p>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setActivePopup(false);
                  }}
                >
                  {t("OK")}
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        ) : (
          ""
        )}
      </div>
      <ErrorModel
        show={errorShow}
        handleClose={() => setErrorShow(false)}
        message={message}
      />
    </>
  );
};

export default PrivacySettings2;
