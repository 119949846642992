import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Navigate, useNavigate } from "react-router";
const QuickTutorial = () => {
  let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const naviageLibrary = () => {
    navigate("/QuickTutorial2");
  };
  return (
    <>
      <div className="mobile-header" style={{ display: "none" }}>
        <div class="tabs_menu_logo">
          <img src="images/albert_logo.svg" alt="" />
        </div>
      </div>
      <div
        className={
          i18n.language == "en"
            ? "space_extra new english"
            : i18n.language == "ru"
            ? "space_extra new russian"
            : i18n.language == "es"
            ? "space_extra new spanish"
            : "space_extra"
        }
      >
        <Container>
          <Row>
            <div className="setting_content_space">
              <div className="privacy setting_content">
                <div className="privacy-policy">
                  <h4>{t("Let’s have a quick tutorial")}</h4>
                  <div className="privacy-content privacy_setting_content tutorial-content">
                    <div className="tutorial-block">
                      <p>
                        {t(
                          "By every article you’ll see 3 pilot lights to indicate what Albert thinks have Clinical Value in your speciality."
                        )}
                      </p>

                      <p className="setting-consent-img">
                        <img src={path_image + "cv_img.png"} alt="" />
                      </p>
                      <p>
                        {t(
                          "Sometimes there is more than one icon to indicate when an article is relevant to more than one speciality."
                        )}
                      </p>
                      <p className="setting-consent-img1">
                        <img src={path_image + "article_choice.png"} alt="" />
                      </p>
                      <p>
                        {t(
                          "To read the abstract tap/click the headline, and either tap it away or click on"
                        )}
                        <img src={path_image + "Close Button.svg"} />
                      </p>
                      <p className="setting-consent-img2">
                        <img
                          src={
                            i18n.language == "ru"
                              ? path_image + "article_list-russian.png"
                              : path_image + "article_list.png"
                          }
                          alt=""
                        />
                      </p>
                    </div>
                    {/* <p>
                    {t(
                      "To help teach Albert we hope you will alert us if something is spot on or wrong by using the thumbs-up and thumbs-down buttons."
                    )}
                  </p>
                  <p className="setting-consent-img3">
                    <img src={path_image + "like_dislike.png"} alt="" />
                  </p>
                  <p>{t("You can also sort by date and type of access")}</p>
                  <div className="setting-consent-img4">
                    <div>
                      <span>Sort By</span>
                      <img src={path_image + "sorted_by.png"} alt="" />
                    </div>
                  </div>
                  <div className="space_line"></div>
                  <div className="article_access">
                    <label>{t("Artical Access")}</label>
                    <ul>
                      <li className="all">{t("All Articles")}</li>
                      <li className="open">{t("Open Access")}</li>
                      <li className="limited">{t("Limited Access")}</li>
                      <li className="free">{t("Free access")}</li>
                    </ul>
                  </div> */}

                    <div className="tutorial-box-footer d-flex justify-content-between">
                      <div className="tutorial-box-layout"></div>
                      <div className="items-selection">
                        <span className="active">&nbsp;</span>
                        <span>&nbsp;</span>
                      </div>
                      <div className="tutorial-box-layout  next-btn">
                        <Button
                          variant="primary"
                          onClick={naviageLibrary}
                          className="consent_btn"
                        >
                          {" "}
                          {t("Next")}
                          <img src={path_image + "next-page.png"} alt="" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default QuickTutorial;
